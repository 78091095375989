/* SuccessModal.css */
.success-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .success-modal {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 35vw;
    height: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .success-image {
    width: 11em;
    height: 11em;
    margin-bottom: 25px;
  }
  
  .success-title {
    color: rgba(29, 29, 29, 0.4);
    font-size: 12px;
    font-family: 'inter';
    margin-bottom: 10px;
  }
  
  .success-amount {
    color: rgba(29, 29, 29, 1);
    font-size: 30px;
    margin-bottom: 5px;
    font-family: 'Space Grotesk', sans-serif;
  }
  
  .success-bought-for {
    color: rgba(29, 29, 29, 0.4);
    font-size: 12px;
    margin-bottom: 20px;
    font-family: 'inter';

  }
  
  .success-message {
    color: rgba(29, 29, 29, 1);
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .success-message p {
    margin: 20px 0;
  }
  
  .success-back-button {
    padding: 10px 20px;
    background-color: #830ECA;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 300px; /* Adjust the width of the button */
    font-family: 'Inter', sans-serif;
  }
  
  .success-back-button:hover {
    background-color: #5a36a1;
  }
  

  .amount-naira {
    color: rgba(29, 29, 29, 1);
  }
  