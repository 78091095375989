/* customDateRangePicker.css */
.DateRangePicker {
    width: 100%;
  }
  
  .DateRangePickerInput {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 3px 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .DateRangePickerInput__arrow {
    margin: 0 10px;
  }
  
  .DateRangePickerInput__clear-dates {
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .DateInput_input {
    font-family: 'Inter';
    font-size: 14px;
    color: #333;
    background-color: transparent;
    height: 30px;
  }
  
  .DayPicker {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
  }
  
  .DayPicker_weekHeader {
    color: #333;
  }
  
  .DayPickerNavigation_button {
    border: none;
    background: none;
    color: #830eca;
    cursor: pointer;
  }
  
  .CalendarMonth_caption {
    font-family: 'Inter';
    font-size: 14px;
    color: #333;
  }
  
  .DayPicker_weekHeader {
    font-family: 'Inter';
    font-size: 14px;
    color: #333;
  }
  
  .DateRangePicker_picker {
    z-index: 1000;
    position: absolute;
    top: 50px; /* Adjust based on your design */
    left: 50%;
    transform: translateX(-50%);
  }
  
  .DateRangePicker_picker__rtl {
    direction: rtl;
  }
  
  .MainTransaction-date-range-modal {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    padding: 20px;
  }
  
  .MainTransaction-date-range-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .MainTransaction-date-range-actions button {
    background-color: #830eca;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: 'Inter';
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .MainTransaction-date-range-input {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 8px; /* Adjust the border-radius to match the rounded corners */
    padding: 10px 15px; /* Adjust padding for a balanced look */
    font-family: 'Inter';
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; /* Ensure the button takes up the full width of its container */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05); /* Add subtle shadow for depth */
  }
  
  .MainTransaction-date-range-input span {
    margin-right: 10px; /* Add some space between the text and the arrow */
  }
  
  .MainTransaction-date-range-input::after {
    content: '▾'; /* Use a down arrow symbol */
    font-size: 12px;
    color: #333; /* Match the text color */
    margin-left: 5px; /* Add 5px space */
  }
  
  .DateRangePicker-wrapper {
    position: relative;
  }
  
  .DateRangePicker-overlay {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    background: white;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .DateRangePicker-close-btn {
    position: absolute;
    top: 0.1rem;
    right: 5px;
    background: #fff; /* White background */
    color: #830eca; /* Purple color */
    border: 1px solid #830eca;
    border-radius: 50%; /* Make the button round */
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    cursor: pointer;
  }
  
  .DateRangePicker-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 10px;
  }
  
  .DateRangePicker-buttons button {
    background-color: #830eca;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: 'Inter';
    font-size: 14px;
    cursor: pointer;
  }
  
  .DateRangePicker-buttons button:hover {
    background-color: #6a0dad;
  }
  
  .DateRangePicker-buttons .clear-btn {
    background-color: #fff;
    color: #830eca;
    border: 1px solid #830eca;
  }
  
  .DateRangePicker-buttons .clear-btn:hover {
    background-color: #d3d3d3;

  }
  