.Profile-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(246, 247, 255);
}

.Profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

.Profile-logo {
  height: 60px;
  margin-left: 45px;
}

.Profile-nav {
  display: flex;
  align-items: center;
  gap: 80px;
  flex-grow: 1; /* Allow nav to grow and fill the available space */
  justify-content: center; /* Center the nav items */
}

.Profile-nav img {
  height: 16px;
  margin-right: 6px;
}

.Profile-nav div {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(150, 150, 150);
  font-family: Inter;
  font-size: 16px;
  cursor: pointer;
}

.Profile-profile {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 4.5em;
}

.Profile-profile-icon {
  width: 60px;
  height: 60px;
  background-color: #6a0dad;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: Inter;
  font-size: 20px;
}

.Profile-content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Profile-wrapper {
  width: 80%;
  max-width: 1000px;
  margin: 20px 0;
}




.Profile-wrapper h1{
  margin-top: 2rem;
font-family: 'Space Grotesk';
font-size: 24px;

}



.Profile-wrapper h2 h3{
font-family: 'Inter';
font-size: 20px;

}


.Profile-wrapper p{
  font-family: 'Inter';
  font-size: 14px;
  
  }


  .Profile-verified {
    display: flex;
    align-items: center;
    background-color: #f3e9ff; /* Light purple background */
    border-radius: 12px;
    padding: 4px 8px;
    font-weight: bold;
    margin-left: 8px;
  }
  
  .Profile-verified-badge {
    margin-left: 4px;
    width: 16px; /* Adjust the size as needed */
    height: 16px; /* Adjust the size as needed */
  }
  

  .Profile-overview {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Adjust the alignment */
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin-top: 2rem;
  }
  
  .Profile-avatar {
    width: 100px; /* Adjust the size as needed */
    height: 100px; /* Adjust the size as needed */
    background-color: lightgray;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 30px;
    margin-right: 20px;
    position: relative;
  }



.Profile-avatar-placeholder {
  color: #666;
}
.Profile-avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.Profile-avatar-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}



.Profile-overview-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto; /* Push the buttons to the right */
}

.Profile-overview-details h2 {
  margin: 0;
  font-size: 20px;
  display: flex;
  align-items: center;
  font-family: 'Inter';
}
.Profile-verified {
  background: rgba(131, 14, 202, 0.10);
  color: #830ECA;
  font-size: 14px;
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  font-style: normal;
font-weight: 400;
font-family: Inter;

}

.Profile-overview-details p {
  margin: 5px 0;
  color: #666;
  font-family: 'Inter';
  font-size: 14px;


}


.Profile-button {
  background-color: #fff; /* Change to white background */
  color: #830eca;
  border: 2px solid #830eca; /* Add border */
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  margin-top: 10px;
}

.Profile-change-password-button {
  background-color: #830eca;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;
  align-self: center; /* Center vertically in the parent */
  margin-left: 1rem;
}

/* Profile.css */

/* Personal Information Section */
.Profile-section {
  background-color: #ffffff; /* Set background color to white */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Inner padding for spacing */
  margin-bottom: 20px; /* Space between sections */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.Profile-section h3 {
  margin-bottom: 15px; /* Space below the title */
  color: #000;
font-family: "Space Grotesk";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.Profile-section p {
  margin: 10px 0; /* Margin around each row */
  color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.Profile-section p strong {
  display: block; /* Display as block to stack the labels */
  font-family: Inter;
  color: #969696;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Profile-section p:not(:last-child) {
  margin-bottom: 20px; /* Space between rows */
}


.Profile-section p span {
  display: block; /* Block for label value to ensure alignment */
  margin-left: 0; /* No margin needed */
  font-weight: normal; /* Normal weight for values */
  color: #000; /* Black color for values */
}

/* Flexbox for layout */
.Profile-section .Profile-row {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: space-between; /* Space between columns */
}

.Profile-section .Profile-column {
  flex: 0 1 48%; /* Flex grow each column equally, minimum width 48% */
  padding: 10px 0; /* Padding for each column */
}

.Profile-section .Profile-column strong {
  display: block; /* Block display for labels */
  margin-bottom: 5px; /* Space between label and value */
}
/* Wrapper for absolute positioning */
.Profile-copy-button-wrapper {
  position: relative; /* Set the wrapper to relative positioning */
  height: 0; /* Ensure the wrapper doesn't affect layout */
}

.Profile-copy-button {
  display: inline-block; /* Keep display inline-block for other buttons */
  padding: 10px 20px; /* Button padding */
  font-family: 'Inter';
  font-size: 14px;
 color: #FFFFFF; /* Button text color */
  border-radius: 5px; /* Rounded corners */
  background: #830ECA; /* Button background color */
  cursor: pointer; /* Pointer cursor */
  text-align: center; /* Center text */
  transition: background 0.3s ease, color 0.3s ease; /* Smooth transitions */
  position: absolute; /* Set the button to absolute positioning */
  right: 0; /* Align to the right */
  top: -13rem; /* Adjust as necessary for alignment */
}

.Profile-copy-button:hover {
  background-color: #6a0dad; /* Background on hover */
  color: #fff; /* Text color on hover */
}


.Profile-delete-button {
  display: block;
  margin: 0 auto; /* Center horizontally */
  padding: 10px 20px; /* Button padding */
  font-size: 16px; /* Button text size */
  font-family: Inter;
  font-style: normal;
font-weight: 600;
line-height: normal;
  color: #FF0000; /* Button text color */
  border: none; /* Remove border */
  background: none; /* Transparent background */
  cursor: pointer; /* Pointer cursor */
  text-align: center; /* Center text */
  position: relative;
  bottom: 1rem; /* 1rem away from the bottom of the parent */
  transform: translateY(50%);
  margin-bottom: 3rem;
}



.Profile-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Graphik Regular';
  font-size: 14px;
  color: rgb(82, 82, 91);
}


.Profile-logout-button {
  background-color: #6a1b9a;
  color: white;
  border: none;
  padding: 10px 50px; /* Adjust this padding to make the button longer */  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 20px 0; /* Adjust margin as needed */
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}


.Profile-logout-button:hover {
  color: darkred;
}