.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 13vw;
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  
  .pagination {
    display: flex;
    align-items: center;
  }
  
  .pagination button {
    background: none;
    border: none;
    color: #000;
    font-size: 1rem;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
    margin: 0 5px;
    transition: background-color 0.3s ease;
  }
  
  .pagination button:hover {
    background-color: #f0f0f0;
  }
  
  .pagination .active {
    background-color: #e5e5ff;
    border: 2px solid #9c9cff;
    color: #000;
  }
  
  .pagination .disabled {
    cursor: not-allowed;
    color: #ccc;
  }
  
  .pagination .dots {
    padding: 5px 10px;
  }
  