@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Graphik:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Graphik:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
/* Tailwind CSS Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles */
body {
  max-width: 100%;
  margin: 0 auto; /* Center align the content */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Prevent horizontal overflow */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Background Image for Header and Hero Section */
.header-hero-bg {
  background: url(/static/media/hero-bg-img.4164a644.png) no-repeat center center;
  background-size: cover;
  padding: 0 5.006em;
  width: 100%;
  height: 100vh; /* Extend to full viewport height */
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
  justify-content: center; /* Center content vertically */
}




/* Custom Class for Gift Cards Image */
.gift-cards-img {
  width: 29.56em;
  height: 31.56em;
  object-fit: contain;
  position: absolute;
  right: 80%; /* Adjust as needed */
  top: 13%; /* Center vertically */
  z-index: 10; /* Ensure it's above other elements */
  animation: rotate-clockwise 10s linear infinite; /* Animation properties */
}

/* Keyframes for Rotation Animation */
@keyframes rotate-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Style for Logo Image */
.logo {
  width: 8.0em;
  height: 3.5em;
  position: relative; /* Allows you to adjust the position */
  top: -5em; /* Adjust the value to move the logo upwards */
  margin-top: 0; /* Remove the default margin-top */
}

/* Base styles */
.header-buttons-container {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  position: absolute;
  top: 1.5rem; /* Adjust the value as needed */
  right: 2rem; /* Adjust the value as needed */
}


.header-button {
  margin-left: 1rem; /* Space between buttons */
  padding: 0.5rem 1rem;
  border-radius: 0.5em; /* Fully rounded corners */
  font-family: 'Graphik-Regular', sans-serif; /* Font family */
  font-size: 0.9rem; /* Font size */
  cursor: pointer; /* Pointer cursor */
}

.header-button-signin {
  background-color: transparent;
  color: white; /* White text color */
  border: 2px solid white; /* White border */
}

.header-button-getstarted {
  background-color: #830eca; /* Purple background */
  color: white; /* White text color */
  border: 2px solid #830eca;
}

.header-button-signin:hover,
.header-button-getstarted:hover {
  opacity: 0.7; /* Slightly transparent on hover */
}


/* Style for Gift Heading */
.gift-heading {
  margin-top: 1em;
  width: 13.25em;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 3.32em;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  color: #ffffff;
}


/* Style for Gift Description */
.gift-description {
  width: 37em; 
  font-family: 'Inter', sans-serif;
  font-size: 1.25em; 
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  color: #ffffff;
  margin-bottom:4.375em; /* Adjust the value as needed */
}

/* Custom Style for Button */
.custom-button {
  width: 14.875em;
  height: 3.875em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 0.625em;
  gap: 0.625em;
  padding: 1.5em 2.9375em;
  border-radius: 0.625em;
  background-color: rgb(255, 184, 0);
  border: none; /* No border color */
  margin-top: 0.625em;
}

/* Custom Style for Button Text */
.button-text {
  width: 6.125em; /* 98px */
  height: 1.5em; /* 24px */
  font-family: 'Graphik-Medium';
  font-size: 1.25em; /* 20px */
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: #830eca;
}

/* CSS for HowItWorks section */
.how-it-works-section {
  padding-top: 5em; /* 80px */
  padding-bottom: 5em; /* 80px */
  background-color: #f7fafc; /* Light gray background */
}

.how-it-works-container {
  max-width: 112rem; /* 1792px */
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  text-align: center;
  width: 80%;
}

.how-it-works-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 2em; /* 32px */
}

.how-it-works-title {
  width: 14.0625em; /* 225px */
  height: 2.875em; /* 46px */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2.25em; /* 36px */
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: #830eca;
  position: relative; /* Position relative to enable absolute positioning of pseudo-elements */
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: center; /* Center content horizontally */
}

.how-it-works-title::before,
.how-it-works-title::after {
  content: "";
  flex: 1 1; /* Adjust this value to change the line length */
  height: 4px; /* Adjust height as needed */
  background-color: #830eca; /* Same color as text */
  margin: 0 1em; /* Adjust spacing as needed */
}

.how-it-works-title::before {
  margin-right: 1em; /* Adjust spacing on the left */
}

.how-it-works-title::after {
  margin-left: 1em; /* Adjust spacing on the right */
}

.how-it-works-steps {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  grid-gap: 1.5em;
  gap: 1.5em; /* 24px */
}

.how-it-works-step {
  width: calc(20% - 1.5em); /* Adjusted width to fit four items in a row */
  max-width: 300px; /* Maximum width for each card */
  background-color: #ffffff; /* White background for the cards */
  padding: 1.5em; /* 24px padding */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow */
  border-radius: 0.75em; /* 12px rounded corners */
  text-align: left;
}

.step-number {
  width: 3.55em; /* 56.8px */
  height: 1.5em; /* 31.8px */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 0.4625em;
  gap: 0.4625em; /* 7.4px */
  padding: 0.4625em; /* 7.4px */
  border-radius: 0.4625em; /* 7.4px */
  background-color: rgba(131, 14, 202, 0.1);
  font-family: 'Inter', sans-serif;
  font-size: 0.875em; /* 14px */
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  color: #830eca;
}

.step-icon {
  width: 3.4375em; /* 55px */
  height: 3.4375em; /* 55px */
  margin-top: 1em;
  margin-bottom: 1em; /* Adjust margin as needed */
}

.verified-message {
  color: #029664;
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px;
font-family: 'Inter', sans-serif;
  animation: fadeOut 2s forwards;

}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.step-title {
  width: 15.125em; /* 242px */
  height: 2.375em; /* 38px */
  font-family: 'Inter', sans-serif;
  font-size: 1em; /* 16px */
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  color: #000000;
  margin-bottom: 0.5em; /* Adjust margin as needed */
}

.step-description {
  width: 13.9875em; /* 223.7px */
  height: 3.1875em; /* 51px */
  font-family: 'Inter', sans-serif;
  font-size: 0.875em; /* 14px */
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  color: #6c6c6c;
  line-height: 1.5em; /* Adjust line-height as needed */
  margin-bottom: 2em;
}


/* CSS for PopularCategories section */
.popular-categories-section {
  margin-left: auto; /* Center align the content */
  margin-right: auto; /* Center align the content */
  width: 80%;
  height: auto;
  background-color: rgba(255, 184, 0, 0.03);
  padding: 5em 0; /* Adjust padding as needed */
}

.popular-categories-container {
  max-width: 112rem; /* 1792px */
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  text-align: center;
  position: relative;
}

.popular-categories-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 2em; /* 32px */
}

.popular-categories-title {
  width: 20.6875em; /* 331px */
  height: 2.875em; /* 46px */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2.25em; /* 36px */
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: #830eca;
  position: relative; /* Position relative to enable absolute positioning of pseudo-elements */
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: center; /* Center content horizontally */
}

.popular-categories-title::before,
.popular-categories-title::after {
  content: "";
  flex: 0.3 1; /* Adjust this value to change the line length */
  height: 4px; /* Adjust height as needed */
  background-color: #830eca; /* Same color as text */
  margin: 0 1em; /* Adjust spacing as needed */
}

.popular-categories-title::before {
  margin-right: 1em; /* Adjust spacing on the left */
}

.popular-categories-title::after {
  margin-left: 1em; /* Adjust spacing on the right */
}

.popular-categories-cards {
  display: flex;
  justify-content: center; /* Center align the content */
  align-items: flex-start;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
}

.category-card {
  width: calc(50% - 1em); /* Two items per row by default */
  min-width: 150px; /* Minimum width to prevent too much compression */
  max-width: 250px; /* Maximum width to ensure they don't get too large */
  height: auto; /* Adjust height to be automatic */
  background-color: #ffffff;
  border-radius: 0.925em; /* 14.8px */
  padding: 1em; /* 16px */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.category-icon {
  width: 8em;
  height: 8em;
}

.category-title {
  font-family: 'Graphik-Regular', sans-serif;
  font-size: 1.125em; /* 18px */
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: #000000;
  margin-top: 1.5em; /* Adjust margin as needed */
  white-space: normal; /* Allow text to wrap */
  overflow: hidden;
  text-overflow: ellipsis;
}

/* CSS for WhyChooseUs section */
.why-choose-us-section {
  padding-top: 5em; /* 80px */
  padding-bottom: 5em; /* 80px */
  background-color: #f7fafc; /* Light gray background */
}

.why-choose-us-container {
  max-width: 80vw; /* 80% of the viewport width */
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  text-align: center;
}

.why-choose-us-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0.2em; /* 32px */
}

.why-choose-us-title {
  width: 16.5625em; /* 265px */
  height: 2.875em; /* 46px */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2.25em; /* 36px */
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: #830eca;
  position: relative; /* Position relative to enable absolute positioning of pseudo-elements */
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: center; /* Center content horizontally */
}

.why-choose-us-title::before,
.why-choose-us-title::after {
  content: "";
  flex: 0.5 1; /* Adjust this value to change the line length */
  height: 4px; /* Adjust height as needed */
  background-color: #830eca; /* Same color as text */
  margin: 0 1em; /* Adjust spacing as needed */
}

.why-choose-us-title::before {
  margin-right: 1em; /* Adjust spacing on the left */
}

.why-choose-us-title::after {
  margin-left: 1em; /* Adjust spacing on the right */
}

.why-choose-us-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 2em;
  gap: 2em; /* Adjust gap as needed */
  width: 60vw; /* Adjust width as needed */
  margin: 0 auto; /* Center the grid */
}

.image-container {
  width: 100%; /* Make it responsive */
  height: 100%; /* Make it responsive */
}

.wide-range {
  grid-column: 1 / span 2; /* Span across column 1 and 2 */
  grid-row: 1; /* Only in row 1 */
  border-radius: 1.975em; /* 31.6px */
  overflow: hidden; /* Ensure the image respects the border-radius */
}

.easy-payments {
  grid-column: 1;
  grid-row: 2;
  border-radius: 2.325em; /* 37.2px */
  overflow: hidden; /* Ensure the image respects the border-radius */
}

.digital-delivery {
  grid-column: 2;
  grid-row: 2;
  border-radius: 2.325em; /* 37.2px */
  overflow: hidden; /* Ensure the image respects the border-radius */
}

.customer-support {
  grid-column: 3;
  grid-row: 1 / span 2; /* Span across row 1 and 2 */
  border-radius: 2.29375em; /* 36.7px */
  overflow: hidden; /* Ensure the image respects the border-radius */
}

/* Ensuring images fit within their containers */
.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container without distortion */
  border-radius: inherit; /* Inherit the border-radius from the parent */
}


/*css styling for FAQ  */

.faq-section {
  padding-top: 5em;
  padding-bottom: 5em;
}

.faq-container {
  max-width: 112rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

.faq-title {
  flex-grow: 0;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #830eca;
  margin-bottom: 3em;
  position: relative; /* Position relative to enable absolute positioning of pseudo-elements */
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: center; /* Center content horizontally */
}

.faq-title::before,
.faq-title::after {
  content: "";
  flex: 0.1 1; /* Adjust this value to change the line length */
  height: 4px; /* Adjust height as needed */
  background-color: #830eca; /* Same color as text */
  margin: 0 1em; /* Adjust spacing as needed */
}

.faq-title::before {
  margin-right: 1em; /* Adjust spacing on the left */
}

.faq-title::after {
  margin-left: 1em; /* Adjust spacing on the right */
}





.faq-list {

  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1em;
  gap: 1em; /* Adjust gap as needed */
  
}



.faq-card {
  width: 90vw; /* Take 90% of the viewport width */
  border-radius: 31.4pt 31.4pt 0 0 ; /* Border radius */
  margin: 0 auto; /* Center the card */
  padding: 2em 0 6em 0; /* Adjust padding as needed */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  transition: max-height 0.4s ease, padding 0.4s ease; /* Smooth transition for hover effect */
  overflow: hidden; /* Hide overflowing content */
  max-height: 4em; /* Initial height */
}







.faq-card .faq-question {
  width: 100%; /* Ensure the question takes the full width */
  text-align: left; /* Align text to the left */
  padding: 0.5emem; /* Padding to prevent text from touching the edge */
}

.faq-card:nth-child(1) {
  background-color: #830eca;
}

.faq-card:nth-child(2) {
  background-color: #ffb800;
}

.faq-card:nth-child(3) {
  background-color: #6200ee;
}

.faq-card:nth-child(4) {
  background-color: #010101;
}

.faq-card:nth-child(5) {
  background-color: #f05e16;

}

.faq-card:hover {
  max-height: 80%; /* Expand to fit content */
  padding: 3em 0 15em 0; /* Adjust padding when expanded */
}


.faq-question {
  font-size: 1.7em; /* Adjust font size as needed */
  font-family: 'Graphik-Medium'; /* Specify the font-family */
  color: #fff; /* Default text color */
  margin: 0; /* Remove default margin */
  text-align: left; /* Align text to the left */
  padding-left: 2.5em; /* Padding to prevent text from touching the edge */
  padding-top: 4em;
  width: 100%; /* Ensure the question takes the full width */
}


.faq-answer {
  font-family: 'Graphik-Medium';
  font-size: 1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;     
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding-left: 4em;
  padding-top: 4em;
}

.faq-card:nth-child(2) .faq-question {
  color: #000; /* Black font color for the second question */
}

.faq-card:nth-child(2) .faq-answer {
  color: #000; /* Black font color for the second question */
}


.faq-card:nth-child(1) {
  margin-top: -5em; /* Adjust to create the overlap */
}
.faq-card:nth-child(2) {
  margin-top: -5em; /* Adjust to create the overlap */
}

.faq-card:nth-child(3) {
  margin-top: -5em; /* Adjust to create the overlap */
}

.faq-card:nth-child(4) {
  margin-top: -5em; /* Adjust to create the overlap */
}
.faq-card:nth-child(5) {
  margin-top: -5em; /* Adjust to create the overlap */
  border-radius: 31.4pt 31.4pt 31.4pt 31.4pt;
  padding: 3em 0 15em 0; /* Adjust padding when expanded */

}


.customer-reviews-section {
  padding: 10em 0; /* 80px */
  background-color: #f7fafc; /* Light gray background */
  text-align: center;
}

.customer-reviews-title {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2.25em; /* 36px */
  font-weight: bold;
  color: #830eca;
  position: relative; /* Position relative to enable absolute positioning of pseudo-elements */
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: center; /* Center content horizontally */
}


.customer-reviews-title::before,
.customer-reviews-title::after {
  content: "";
  flex: 0.1 1; /* Adjust this value to change the line length */
  height: 4px; /* Adjust height as needed */
  background-color: #830eca; /* Same color as text */
  margin: 0 1em; /* Adjust spacing as needed */
}


.customer-reviews-title::before {
  margin-right: 1em; /* Adjust spacing on the left */
}

.customer-reviews-title::after {
  margin-left: 1em; /* Adjust spacing on the right */
}



.customer-review {
  width: 80vw; /* Take 90% of the viewport width */
  height: 55vh; /* Take 50% of the viewport height */
  background-size: cover;
  background-position: center;
  border-radius: 31.6px;
  padding: 1.5em;
  position: relative;
  text-align: left;
  margin: 0 auto; /* Center the review */
}
.customer-review-text {
  color: white;
  font-family: 'Space Grotesk', sans-serif;

  font-size: 2.813em; /* 24px */
  position: absolute;
  top: 10%;
  left: 5%;
  width: 70%;
  transform: translateY(-30%);
}

.review-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.prev-button,
.next-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 2em; /* Adjust the value as needed for spacing */
}

.prev-button img,
.next-button img {
  width: 40px;
  height: 40px;

}

.cta-section {
  padding: 10em 0;
  background-color: #f7fafc; /* Light gray background */
  position: relative;
  overflow: hidden;
  text-align: center;
}


/* Gradient Blur Class */
.gradient-blur {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: linear-gradient(to bottom, #dba3fd, #830eca);
  opacity: 0.24;
  filter: blur(120px);
  z-index: 0;
}



.cta-container {
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  width: 80vw; /* Take 70% of the viewport width */
  margin: 0 auto; /* Center the card */
  padding: 2em; /* Adjust padding as needed */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.3em;
}



.cta-title {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2.75em; /* 44px */
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: #0c0e0f;
  margin-bottom: 0.5em; /* Adjust margin as needed */
}

.cta-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 1.27em; /* 20.3px */
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  color: #0c0e0f;
  margin-bottom: 2em; /* Adjust margin as needed */
}


.cta-button-container {
  display: flex;
  justify-content: center;
}


.cta-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  border-radius: 6px;
  background-color: #830eca;
  color: white;
  font-family: 'Graphik-Regular', sans-serif;
  font-size: 1em; /* Adjust font size as needed */
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
  margin-top: 0em;
  margin-bottom: 5em;
}

.cta-button:hover {
  background-color: #6d0fb7; /* Darker shade for hover effect */
}

/* Styling for Footer */
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem; /* Adjust as needed */
  padding-bottom: 1.5rem; /* Add padding to move the line down */
  border-bottom: 1px solid #e5e7eb; /* Grey line */
}

.footer-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


.footer-logo {
    display: flex;

  height: 2.1rem; /* 10em */
  margin-right: auto; /* Move logo to the left */
  position: relative;
  top: 2.5rem; /* Adjust this value to move the logo downwards */
  margin-bottom: 1rem; /* Add space below the logo */

  left: 7.5rem;
}


.footer-links {
  display: flex;
  flex-direction: row; /* Keep links in a row */
  justify-content: center;
}


.footer-link:last-child {
  margin-right: 0; /* Remove margin for the last element */
}


.footer-link {
  margin-right: 2rem; /* Adjust spacing as needed */
  font-family: 'Graphik', sans-serif; /* Add this line */
  font-weight: 500;
  color: #0c0e0f;
  text-decoration: none;
  transition: color 0.3s ease;

}


.footer-link:hover {
  color: #000000;
}

.footer-social {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem; /* 4em */
  margin-left: auto; /* Move logo to the left */
  position: relative;
  top: -2.1rem; /* Adjust this value to move the logo downwards */
  right: 8.5rem;
  flex-direction: row; /* Keep social icons in a row */
  justify-content: center;

}



.social-link {
  color: #0c0e0f;
  transition: color 0.3s ease;
  font-size: 1.25rem; /* Add this line for adjusting the font size */

}


.social-link {
  margin-right: 1rem; /* Adjust spacing as needed */
}

.social-link:last-child {
  margin-right: 0; /* Remove margin for the last element */
}

.social-link:hover {
  color: #000000;
}

.footer-bottom {
  display: flex;
  justify-content: space-evenly;
  text-align: left;
  margin-top: 1.5rem; /* 6em */
}

.footer-bottom p {
  margin: 0.5em 0;
  flex: 1 1; /* Allow p elements to take available space */
}

.footer-bottom p:first-child {
  text-align: left;
  margin-left: 7.5rem;
}

.footer-bottom p:last-child {
  text-align: right;
  margin-right: 8.5rem;


}

.footer-bottom {
  color: #4a4a4a; /* Dark grey color */
}/* SignUp page styles */





/* Styling s for the signup.js page*/


.signup-container-custom {
  display: flex;
  height: 75vh;
  width: 65vw;
  margin: auto;
  border-radius: 8px; /* optional: adds rounded corners */
  overflow: hidden; /* optional: ensures children do not overflow */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.signup-left-custom {
  width: 40%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: #ffffff;
}

.signup-right-custom {
  width: 60%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signup-logo {
  width: 15em; /* Adjust the logo size as needed */
  height: auto; /* Maintain aspect ratio */
}

.signup-title-custom {
  font-size: 1.1rem;
  margin-left: 1.2rem;
  font-family: Space Grotesk;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgb(39, 39, 39);
  margin-top: 1em;
  
}

.signup-form-custom {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.form-group-custom {
  margin-bottom: 1rem;
}

.form-group-custom label {
  display: block;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
  font-family: Graphik Medium;

  
}

.form-group-custom input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  font-family: Graphik Medium;

}

.signup-button-custom {
  width: 107%; /* Make the button the same width as the form */
  padding: 15px;
  background-color: rgb(131, 14, 202);  
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  font-family: Graphik Medium;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.signup-button-custom:hover {
  background-color: #574bff; /* Adjust to your brand color */
}

.signup-link-custom {
  display: block;
  text-align: left;
  font-size: 0.9rem;
  color: #2b2b2b; /* Adjust to your brand color */
  text-decoration: none;
  margin-left: 2rem;
}
.signup-link-custom:hover {
  text-decoration: none;
}

.signup-link-custom a {
  color: #830eca; /* Adjust to your brand color */
  text-decoration: none;
}

.signup-link-custom span {
  text-decoration: none;
}

.signup-link-custom a:hover {
  text-decoration: none;
}

.logo-title {
  font-size: 1.5rem; /* Adjust this value to make the logo title smaller */
  margin-top: 1rem;
}

.logo-description {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.form-group-inline {
  display: flex;
  justify-content: space-between;
  grid-gap: 3rem;
  gap: 3rem; /* Adjust this value to set the desired gap */
}



.form-group-inline .form-group-custom {
  flex: 1 1; /* Adjust the width of each inline form group */
}
/* Additional styles for password visibility toggle */
.password-container {
  position: relative;
  width: 100%;
}

.password-container input {
  width: calc(101% - 5px); /* Subtract the space needed for the toggle icon */
}

.password-toggle-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
  font-size: 0.9rem;
}



/* Styling for SignIn.js page*/

.signin-container-custom {
  display: flex;
  height: 60vh;
  width: 50vw;
  margin: auto;
  border-radius: 8px; /* optional: adds rounded corners */
  overflow: hidden; /* optional: ensures children do not overflow */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.signin-left-custom {
  width: 40%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: #ffffff;
}

.signin-right-custom {
  width: 60%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signin-logo {
  width: 15em; /* Adjust the logo size as needed */
  height: auto; /* Maintain aspect ratio */
}


.signin-title-custom {
  font-size: 1.5rem; /* Initial font size for the title */
  margin-top: 0; /* Reset any previous margin */
  top: 0; /* Reset any previous top value */
  font-family: Space Grotesk;
  margin-left: 1.1rem; /* Move the title to the right */



}



/*

.signin-title-custom {
  font-size: 1.2rem;
  margin-left: 1rem;
  font-family: Space Grotesk;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgb(39, 39, 39);
  position: absolute;
  top: 1rem;
}
form-group-custom


*/
.signin-form-custom {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  margin-top: 2rem; /* Add margin to push it down */
}


.form-group-custom {
  margin-bottom: 1rem;
}


.form-group-custom label {
  display: block;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
  font-family: Graphik Medium;
}

.form-group-custom input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  font-family: Graphik Medium;
}

.signin-button-custom {
  width: 107%; /* Make the button the same width as the form */
  padding: 15px;
  background-color: #830eca;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  font-family: Graphik Medium;
  transition: background-color 0.3s ease;
}

.signin-button-custom:hover {
  background-color: #574bff; /* Adjust to your brand color */
}

.signin-link-custom {
  display: block;
  text-align: left;
  font-size: 0.9rem;
  color: #2b2b2b; /* Adjust to your brand color */
  text-decoration: none;
  margin-top: 1rem; /* Adjust this value to position it closer to the button */
  font-family: Graphik Medium;


}

.forgot-password-link {
  text-align: left;
  margin-top: 2rem; /* Adjust this value to move it around */
}

.forgot-password-link a {
  color: #830eca; /* Adjust to your brand color */
  text-decoration: underline;
  font-family: Graphik Medium;
}

.forgot-password-link a:hover {
  text-decoration: underline;
}




.signin-link-custom:hover {
  text-decoration: none;
}

.signin-link-custom a {
  color: #830eca; /* Adjust to your brand color */
  text-decoration: none;
}

.signin-link-custom span {
  text-decoration: none;
}

.signin-link-custom a:hover {
  text-decoration: none;
}

.logo-title {
  font-size: 1.5rem; /* Adjust this value to make the logo title smaller */
  margin-top: 1rem;
}

.logo-description {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.password-container {
  position: relative;
  width: 100%;
}

.password-container {
  position: relative;
  width: 100%;
}

.password-container input {
  width: calc(101% - 5px); /* Subtract the space needed for the toggle icon */
}
.password-toggle-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
  font-size: 0.9rem;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  text-align: left;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}


.forgot-password-link {
  text-align: left;
  font-size: 0.9rem;
  color: #830eca; /* Adjust to your brand color */
  text-decoration: none;
  margin-bottom: 1rem; /* Add margin to position above the submit button */
}

.forgot-password-link:hover {
  text-decoration: underline;
}


/* Styling for ResetPassword.js page */
.reset-password-container-custom {
  display: flex;
  height: 60vh;
  width: 50vw;
  margin: auto;
  border-radius: 8px; /* optional: adds rounded corners */
  overflow: hidden; /* optional: ensures children do not overflow */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.reset-password-left-custom {
  width: 40%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: #ffffff;
}

.reset-password-right-custom {
  width: 60%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reset-password-logo {
  width: 15em; /* Adjust the logo size as needed */
  height: auto; /* Maintain aspect ratio */
}

.reset-password-title-custom {
  font-size: 1.2rem;
  margin-left: 1.5rem;
  font-family: Space Grotesk;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgb(39, 39, 39);
  position: absolute;
  top: 4rem;
}

.reset-password-form-custom {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.form-group-custom {
  margin-bottom: 1rem;
}

.form-group-custom label {
  display: block;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
  font-family: Inter;
}

.form-group-custom input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  font-family: Inter;
}





.reset-password-button-custom {
  width: 107%; /* Make the button the same width as the form */
  padding: 15px;
  background-color: rgb(131, 14, 202);  
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  font-family: Graphik Medium;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.reset-password-button-custom:hover {
  background-color: #574bff; /* Adjust to your brand color */
}

.back-to-login-link {
  display: block;
  text-align: left;
  font-size: 0.9rem;
  color: #2b2b2b; /* Adjust to your brand color */
  text-decoration: none;
  margin-left: 2rem;
}

.back-to-login-link:hover {
  text-decoration: none;
}

.back-to-login-link a {
  color: #830eca; /* Adjust to your brand color */
  text-decoration: none;
}

.back-to-login-link span {
  text-decoration: none;
}

.back-to-login-link a:hover {
  text-decoration: none;
}

.password-container {
  position: relative;
  width: 100%;
}

.password-container input {
  width: calc(101% - 5px); /* Subtract the space needed for the toggle icon */
}

.password-toggle-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
  font-size: 0.9rem;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  text-align: left;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.success-message {
  color: green;
  font-size: 0.9rem;
  text-align: left;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}


/* Reset Password Modal styles */
.reset-password-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
}

.reset-password-modal-content {
  position: relative;
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  width: 80%;
  text-align: center;
  font-family: 'Graphik Medium', sans-serif;
}

.reset-password-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  font-size: 24px;
  color: #333; /* Adjust color as needed */
}

.reset-password-success-image {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  margin-bottom: 15px;
}

.reset-password-modal-title {
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  margin-bottom: 20px;
  font-family: Graphik Semibold;
  font-size: 32px;
  font-weight: 600;
  color: rgb(131, 14, 202);
}

.reset-password-modal-text {
  margin-bottom: 20px;
  color: rgb(57, 57, 57);
  font-family: Graphik Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-50px); }
  to { transform: translateY(0); }
}

/* Global transition styles */
.page-transition-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.page-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.page-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.page-transition-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}




/* Media Queries */

/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .header-hero-bg {
    padding: 0 6em;
    height: 70vh; /* Adjust this value as needed */

  }

  .logo {
      width: 13.31em;
      height: 6em;
      top: -4em; /* Adjust the value to move the logo upwards */
      left: -3em;

  }

  .gift-cards-img {
  display: none;
  }

  .gift-heading {
    font-size: 3.5em;
    position: relative;
    top: -1em; /* Adjust the value to move the logo upwards */
    left: -1em;
  }

  .gift-description {
    font-size: 1.4em;
    width: 30em;
    position: relative;
    top: -2.5em; /* Adjust the value to move the logo upwards */
    left: -2.5em;
  }

  .custom-button {
    padding: 1.6em 3em;
    position: relative;
    top: -3.5em; /* Adjust the value to move the logo upwards */
    left: -4.3em;
  }

  .button-text {
    font-size: 1.3em;
  }


  .header-buttons-container {
    position: absolute;
    top: 4rem; /* Adjust this value to position the buttons correctly */
    right: 1rem; /* Adjust this value to position the buttons correctly */
    flex-direction: row; /* Stack buttons vertically on smaller screens */
    align-items: flex-end;
  }

  .how-it-works-step {
    width: calc(40% - 1.5em); /* Two items per row */
  }
  .popular-categories-cards {
    justify-content: space-around; /* Ensure cards are spaced evenly */
  }

  .category-card {
    width: calc(50% - 1em); /* Two items per row */
  }


  .why-choose-us-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    grid-template-rows: auto auto auto; /* Three rows */
    grid-gap: 1em;
    gap: 1em; /* Adjust gap as needed */
    width: 50vw; /* Slightly smaller grid */
  }

  .wide-range {
    grid-column: 1 / span 2; /* Span across both columns */
    grid-row: 1; /* First row */
    border-radius: 1.975em; /* 31.6px */
    overflow: hidden; /* Ensure the image respects the border-radius */
  }

  .easy-payments {
    grid-column: 1; /* First column */
    grid-row: 2; /* Second row */
    border-radius: 2.325em; /* 37.2px */
    overflow: hidden; /* Ensure the image respects the border-radius */
  }

  .digital-delivery {
    grid-column: 2; /* Second column */
    grid-row: 2; /* Second row */
    border-radius: 2.325em; /* 37.2px */
    overflow: hidden; /* Ensure the image respects the border-radius */
  }

  .customer-support {
    grid-column: 1 / span 2; /* Span across both columns */
    grid-row: 3; /* Third row */
    width: 100%;
    height: auto; /* Adjust height as needed */
  }

  .customer-support .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image fits within the container */
  }


  .customer-reviews-section {
    padding: 5em 0; /* Adjust padding as needed */
  }

  .customer-reviews-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em; /* Adjust margin for better spacing */
  }

  .customer-reviews-title {
    font-size: 1.5em; /* Reduce font size for better readability on smaller screens */
  }


  .customer-review {
    width: 90vw; /* Adjust width to fit better on smaller screens */
    height: 40vh; /* Set a fixed height */
    background-size: contain; /* Ensure the background image scales properly */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent background image from repeating */
    border-radius: 31.6px;
    padding: 1.5em;
    position: relative;
    text-align: left;
    margin: 0 auto; /* Center the review */
  }


  .customer-review-text {
    color: white;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 1.5em; /* Reduce font size for better readability on smaller screens */
    position: absolute;
    top: 20%; /* Center vertically */
    left: 5%;
    width: 90%;
    transform: translateY(-50%); /* Adjust to center text vertically */
  }


  .review-navigation {
    margin-top: 0.5em; /* Adjust margin for better spacing */
  }
  .footer-top {
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
  }

 

  .footer-logo {
    display: flex;
    justify-content: center;
    margin: 0 auto 1.5rem auto; /* Center logo and add space below */
    position: relative;
    left: 0; /* Reset left position */
    top: 0; /* Reset top position */
  }

  .footer-links {
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem; /* Adjust spacing as needed */
    margin-bottom: 1rem; /* Add space below links */
    font-size: 0.75em;

  }

  .footer-social {
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem; /* Adjust spacing as needed */
    margin-top: 1rem; /* Add space above social icons */
    position: relative;
    left: 0; /* Reset left position */
    right: 0; /* Reset right position */
    top: 0; /* Reset top position */
    margin-left: auto; /* Remove left margin auto */
    margin-right: auto; /* Remove right margin auto */
    font-size: 0.5em;

  }
 
  .footer-bottom p {
    margin-left: 5rem;  
  }

  .footer-bottom {
    margin-bottom: 2em;
    font-size: 0.8em;

  }

  .signin-title-custom {
    font-size: 0.5rem; /* Smaller font size for medium screens */
    top: 0; /* Reset any previous top value */
  }

  .signin-form-custom {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-top: 2rem; /* Add margin to push it down */
  }
  
  .form-group-custom {
    margin-bottom: 1rem;
  }




    }
    




/* Laptops and Desktops */
@media (min-width: 1024px) and (max-width: 1439px) {
  .header-hero-bg {
    padding: 0 8em;
    height: 80vh; /* Adjust this value as needed */

  }

    .forgot-password-container-custom {
      height: 60vh;
      width: 60vw;
  
    }



  .signin-title-custom {
    font-size: 0.5rem; /* Adjusted font size */
    margin-top: -3rem; /* Adjusted margin for vertical positioning */
    margin-left: 3.5rem; /* Move the title to the right */
  }

  .signin-form-custom {
    margin-top: 2rem; /* Maintain the margin-top for spacing */
    margin-left: 2rem; /* Move the form to the right */
  }

  .form-group-custom {
    margin-top: 1rem; /* Increase the margin for form groups */
  }

  .logo {
    width: 10.31em;
    height: 2.3em;
    top: -12em; /* Adjust the value to move the logo upwards */
    left: -5em;
    margin-top: 0; /* Remove the default margin-top */
  }

  .header-buttons-container {
    position: absolute;
    top: 4rem; /* Adjust the value as needed */
    right: 2rem; /* Adjust the value as needed */
  }
  

  .gift-cards-img {
  display: none;
  }

  .gift-heading {
    font-size: 4em;
    position: relative;
    top: -2em; /* Adjust the value to move the logo upwards */
    left: -1.3em;
  }

  .gift-description {
    font-size: 1.5em;
    width: 38em;
    position: relative;
    top: -5em; /* Adjust the value to move the logo upwards */
    left: -3.2em;
  }

  .custom-button {
    padding: 1.8em 3.2em;
    position: relative;
    top: -6.5em; /* Adjust the value to move the logo upwards */
    left: -6em;
  }

  .button-text {
    font-size: 1.4em;
  }


  .why-choose-us-grid {
    display: grid;
    grid-template-rows: auto auto auto; /* Three rows */
    grid-gap: 1em;
    gap: 1em; /* Adjust gap as needed */
    width: 80vw; /* Slightly smaller grid */
  }

  .popular-categories-cards {
    justify-content: space-around; /* Ensure cards are spaced evenly */
  }
  
  .wide-range, .easy-payments, .digital-delivery {
    width: 100%; /* Make grid items full width on small screens */
    height: auto; /* Adjust height as needed */
  }


  .customer-support .card-image {
    width: 105%;
    height: 100%;
    object-fit: cover; /* Ensure the image fits within the container */
  }

    .how-it-works-steps {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Two items per row */
      grid-gap: 2rem;
      gap: 2rem; /* Add gap between the grid items */
      justify-content: center; /* Center the grid items */
      width: 70%; /* Adjust the width of the grid container */
      margin: 0 auto; /* Center the grid container */
    }
  
    .how-it-works-step {
      max-width: 500px; /* Maximum width for each card */
      min-width: 300px; /* Minimum width for each card */
    
  }
  


  .customer-reviews-section {
    padding: 5em 0; /* Adjust padding as needed */
  }

  .customer-reviews-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em; /* Adjust margin for better spacing */
  }

  .customer-reviews-title {
    font-size: 1.75em; /* Adjust font size for better readability */
  }

  .customer-review {
    width: 85vw; /* Adjust width to fit better on larger screens */
    height: 45vh; /* Set a fixed height */
    background-size: contain; /* Ensure the background image scales properly */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent background image from repeating */
    border-radius: 31.6px;
    padding: 1.5em;
    position: relative;
    text-align: left;
    margin: 0 auto; /* Center the review */
  }

  .customer-review-text {
    color: white;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 1.75em; /* Adjust font size for better readability */
    position: absolute;
    top: 20%; /* Center vertically */
    left: 5%;
    width: 90%;
    transform: translateY(-50%); /* Adjust to center text vertically */
  }



  .footer-top {
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
  }

  .footer-logo {
    display: flex;
    justify-content: center;
    margin: 0 auto 1.5rem auto; /* Center logo and add space below */
    position: relative;
    left: 0; /* Reset left position */
    top: 0; /* Reset top position */
  }

  .footer-links {
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem; /* Adjust spacing as needed */
    margin-bottom: 1rem; /* Add space below links */
    font-size: 0.75em;
  }

  .footer-social {
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem; /* Adjust spacing as needed */
    margin-top: 1rem; /* Add space above social icons */
    position: relative;
    left: 0; /* Reset left position */
    right: 0; /* Reset right position */
    top: 0; /* Reset top position */
    margin-left: auto; /* Remove left margin auto */
    margin-right: auto; /* Remove right margin auto */
    font-size: 0.5em;

  }


  .footer-bottom {
    margin-bottom: 2em;
    font-size: 0.8em;

  }

  .footer-bottom p {
    margin-left: 5rem;  

  }
}




/* Large Desktops */
@media (min-width: 1440px) and (max-width: 1710px) {
  .header-hero-bg {
    padding: 0 5em;
    height: 90vh;
  }



  .logo {
    width: 10.31em;
    height: 5em;
    top: -5em; /* Adjust the value to move the logo upwards */
    margin-bottom: 2em;
  }

  .header-buttons-container {
    position: absolute;
    top: 3.5rem; /* Adjust the value as needed */
    right: 2rem; /* Adjust the value as needed */
  }


  .gift-cards-img {
    width: 28em; /* Larger size for larger screens */
    height: 32em;
    right: 10%;
    top: 22%;
  }

  .gift-heading {
    font-size: 4em;
    top: -8em; /* Adjust the value to move the logo upwards */
    left: 7.3em;
  }

  .gift-description {
    font-size: 1.5em;
    width: 25em;
  }

  .custom-button {
    padding: 1.5em 3em;
  }

  .button-text {
    font-size: 1.3em;
  }


  .gift-heading {
    margin-top: -0.5em;
  }


  .how-it-works-steps {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four items per row */
    grid-gap: 2rem;
    gap: 2rem; /* Add gap between the grid items */
    justify-content: center; /* Center the grid items */
    width: 70%; /* Adjust the width of the grid container */
    margin: 0 auto; /* Center the grid container */
  }

  .how-it-works-step {
    max-width: 350px; /* Maximum width for each card */
    min-width: 250px; /* Minimum width for each card */
  }



  .why-choose-us-grid {
    display: grid;
    grid-template-rows: auto auto auto; /* Three rows */
    grid-gap: 1em;
    gap: 1em; /* Adjust gap as needed */
    width: 80vw; /* Slightly smaller grid */
  }

  .popular-categories-cards {
    justify-content: space-around; /* Ensure cards are spaced evenly */
  }
  
  .wide-range, .easy-payments, .digital-delivery {
    width: 100%; /* Make grid items full width on small screens */
    height: auto; /* Adjust height as needed */
    justify-content: space-around; /* Ensure cards are spaced evenly */

  }


  .customer-support .card-image {
    width: 105%;
    height: 100%;
    object-fit: cover; /* Ensure the image fits within the container */
  }


  .customer-reviews-section {
    padding: 5em 0; /* Adjust padding as needed */
  }

  .customer-reviews-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em; /* Adjust margin for better spacing */
  }

  .customer-reviews-title {
    font-size: 2em; /* Adjust font size for better readability */
  }

  .customer-review {
    width: 80vw; /* Adjust width to fit better on larger screens */
    height: 50vh; /* Set a fixed height */
    background-size: contain; /* Ensure the background image scales properly */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent background image from repeating */
    border-radius: 31.6px;
    padding: 1.5em;
    position: relative;
    text-align: left;
    margin: 0 auto; /* Center the review */
  }

  .customer-review-text {
    color: white;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 2em; /* Adjust font size for better readability */
    position: relative;
    top: 20%; /* Center vertically */
    width: 80%;
    transform: translateY(-50%); /* Adjust to center text vertically */

  }
    .footer-top {
      flex-direction: column;
      align-items: center;
      margin-top: 2em;
    
  }
  .footer-bottom {
    margin-bottom: 4em;
    font-size: 0.8em;

  }
}





/* Very Large Desktops */
@media (min-width: 1711px) {
  .header-hero-bg {
    padding: 0 7em;
    height: 95vh;
  }


  .logo {
    width: 8.0em;
    height: 3.5em;
    top: -13em; /* Adjust the value to move the logo upwards */
    margin-top: 0; /* Remove the default margin-top */

  }

  .header-buttons-container {
    left: 118em; /* Move left by 20 pixels */
    top: 3em;
  }


  .header-button {

    font-size: 1.2rem; /* Font size */
  }

  
  .gift-cards-img {
    width: 40em; /* Even larger size for very large screens */
    height: 45em;
    right: 15%;
    top: 12%;

  }
  .gift-heading {
    font-size: 4em;
    top: -10em; /* Adjust the value to move the logo upwards */
    left: 7.3em;
  }



  .how-it-works-steps {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four items per row */
    grid-gap: 2rem;
    gap: 2rem; /* Add gap between the grid items */
    justify-content: center; /* Center the grid items */
    width: 95%; /* Adjust the width of the grid container */
    margin: 0 auto; /* Center the grid container */
  }

  .how-it-works-step {
    max-width: 350px; /* Maximum width for each card */
    min-width: 300px; /* Minimum width for each card */
  }

  .popular-categories-cards {
    justify-content: space-around; /* Ensure cards are spaced evenly */
    width: 70%; /* Adjust the width of the grid container */
    margin-left: 20em;

  }

  .gift-description {
    font-size: 1.8em;
    width: 30em;
  }

  .custom-button {
    padding: 2em 4em;
  }

  .button-text {
    font-size: 1.4em;
  }


  .gift-heading {
    margin-top: -0.5em;
  }


    .why-choose-us-grid {
      grid-template-columns: repeat(3, 1fr); /* Three columns for larger screens */
      grid-template-rows: repeat(2, auto); /* Adjust rows as needed */
      grid-gap: 2em;
      gap: 2em; /* Adjust gap as needed */
      width: 60vw; /* Adjust width as needed */
      height: 75vh; /* Adjust height as needed */
            }
    
    .wide-range, .easy-payments, .digital-delivery, .customer-support {
      width: 100%; /* Make grid items full width on larger screens */
      height: auto; /* Adjust height as needed */
    }

    .faq-card {
      width: 80vw; /* Take 90% of the viewport width */
    
    }
    
    
    .how-it-works-steps {
      justify-content: space-between; /* Ensure cards are spaced evenly */
    }
  
    .how-it-works-step {
      width: calc(23% - 1.5em); /* Four items per row, slightly adjusted */
      max-width: 23%; /* Ensure they don't exceed the row */
    }


    .footer-top {
      flex-direction: column;
      align-items: center;
      margin-top: 2em;
    
  }
  .footer-bottom {
    margin-bottom: 4em;
    font-size: 0.8em;

  }
}

/* Loading animation styles */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader::before,
  .loader::after,
  .loader div {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    margin: 5px;
    border-radius: 50%;
    background: #fff;
    animation: loader-animation 1.2s infinite ease-in-out;
  }
  
  .loader::before {
    animation-delay: -0.32s;
  }
  
  .loader::after {
    animation-delay: -0.16s;
  }
  
  @keyframes loader-animation {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  

/* Modal styles */
.signup-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
  }
  
  /* Add this to signup-modal.css */
.signup-resend-button {
  background: none;
  border: none;
  color: #830eca;
  cursor: pointer;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  padding: 0;
}



  .signup-modal-content {
    position: relative;
  
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    max-width: 600px;
    width: 80%;
    text-align: center;
    font-family: 'Graphik Medium', sans-serif;
  }
  
  .signup-close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-size: 24px;
    color: #333; /* Adjust color as needed */
  
  }
  
  .signup-success-image {
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    margin-bottom: 15px;
  }
  
  .signup-modal-title {
  
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;  
    margin-bottom: 20px;
    font-family: Graphik Semibold;
    font-size: 32px;
    font-weight: 600;  color: rgb(131, 14, 202);
  
  }
  
  .signup-modal-text {
    margin-bottom: 20px;
    color: #393939;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }
  
  .signup-modal-text a {
    text-decoration: none;
    color: #830ECA;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  }
  
  .signup-modal-text a:hover {
    text-decoration: underline;
  }
  
  .signup-modal-footer {
    color: #393939;
    text-decoration: none;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  }
  
  .signup-modal-footer a {
    text-decoration: none;
    color: #830ECA;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  }
  
  .resend-button-custom {
  text-decoration: none;
  color: #830ECA;
  font-family:  'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  background: none;
  border: none;
  cursor: pointer;
}

  
.resend-button-custom:disabled {
  cursor: not-allowed;
}


/* Modal styles */
.Forgetpassword-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
  }
  
  .Forgetpassword-modal-content {
    position: relative;
  
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    max-width: 600px;
    width: 80%;
    text-align: center;
    font-family: 'Graphik Medium', sans-serif;
  }
  
  .Forgetpassword-close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-size: 24px;
    color: #333; /* Adjust color as needed */
  
  }
  
  .success-image {
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    margin-bottom: 15px;
  }
  
  .Forgetpassword-modal-title {
  
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;  
    margin-bottom: 20px;
    font-family: Graphik Semibold;
    font-size: 32px;
    font-weight: 600;  color: rgb(131, 14, 202);
  
  }
  
  .Forgetpassword-modal-text {
    margin-bottom: 20px;
    color: #393939;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }
  
  .Forgetpassword-modal-text a {
    text-decoration: none;
    color: #830ECA;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  }
  
  .Forgetpassword-modal-text a:hover {
    text-decoration: underline;
  }
  
  .Forgetpassword-modal-footer {
    color: #393939;
    text-decoration: none;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  }
  
  .Forgetpassword-modal-footer a {
    text-decoration: none;
    color: #830ECA;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  }
  
/* Styling for ForgotPassword.js page */
.password-form-group-custom,
.forgot-password-button-custom {
  max-width: 350px; /* Set your desired max-width here */
  width: 100%; /* Ensure they take the full width available up to max-width */
}

.forgot-password-container-custom {
  display: flex;
  height: 60vh;
  width: 50vw;
  margin: auto;
  border-radius: 8px; /* optional: adds rounded corners */
  overflow: hidden; /* optional: ensures children do not overflow */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.forgot-password-left-custom {
  width: 40%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: #ffffff;
}

.forgot-password-right-custom {
  width: 60%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.forgot-password-logo {
  width: 15em; /* Adjust the logo size as needed */
  height: auto; /* Maintain aspect ratio */
}

.forgot-password-title-custom {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 32px;
  font-weight: bold;
  line-height: normal;
  text-align: left;
  color: rgb(39, 39, 39);
  margin-bottom: 1rem; /* Margin below the title */
}

.forgot-password-form-group {
  width: 100%;
  margin-bottom: 1rem; /* Margin below the form group */
}

.forgot-password-input {
  width: 100%; /* Ensure it takes the full width of the container */
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  font-family: Inter;
  margin-bottom: 1rem; /* Margin below the input */
  margin-top: 0.5rem;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.forgot-password-button {
  width: 100%; /* Ensure it takes the full width of the container */
  padding: 15px;
  background-color: #830eca; /* Adjust color to match the second image */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 1rem; /* Margin below the button */
  font-family: Inter;
  transition: background-color 0.3s ease;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.forgot-password-button:hover {
  background-color: #6a0ca9; /* Darker shade for hover effect */
}

.back-to-login-link {
  width: 100%; /* Ensure it takes the full width of the container */
  text-align: left; /* Left align the link */
  font-size: 14px;
  color: #830eca;
  text-decoration: none;
}

.back-to-login-link a {
  color: #830eca;
  text-decoration: none;
}

.back-to-login-link a:hover {
  text-decoration: underline;
}

.NairaInput-container {
    position: relative;
    width: 100%;
  }
  
  .NairaInput-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Graphik Regular', sans-serif;
    text-align: left;
    padding-left: 40px; /* Add padding to leave space for the Naira symbol */
    box-sizing: border-box; /* Ensure padding and border are included in width */
  }
  
  .NairaInput-input::placeholder {
    color: #ccc;
    font-family: 'Graphik Regular', sans-serif;
  }
  
  .NairaInput-container::before {
    content: '₦';
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #000;
    font-family: 'Graphik Regular', sans-serif;
  }
  
.AddMoneyModal-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.AddMoneyModal-modal-content {
    background: white;
    padding: 20px;
    border-radius: 12px;
    width: 20%;
    max-width: 500px;
    font-family: 'Graphik Regular', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AddMoneyModal-modal-header {
    display: flex;
    justify-content: space-between; /* Change to space-between */
    align-items: center;
    padding-bottom: 10px;
    width: 100%;
}

.AddMoneyModal-modal-header h3 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Space Grotesk', sans-serif;
    color: rgb(131, 14, 202);
    margin: 0;
    flex: 1 1; /* Take up remaining space */
    text-align: center; /* Center align the header */
}

.AddMoneyModal-close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.AddMoneyModal-back-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.AddMoneyModal-modal-body {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.AddMoneyModal-add-money-options {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    width: 70%;
}

.AddMoneyModal-add-money-option {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 0.8em;
}




.AddMoneyModal-generate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .AddMoneyModal-generate-message {
    font-size: 12px;
    margin-bottom: 20px;
    font-family: 'inter';
  }
  
  .AddMoneyModal-generate-button {
    background-color: #6a1b9a;
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s;
    font-family: 'inter';

  }
  
  .AddMoneyModal-generate-button:hover {
    background-color: #5a148a;
  }
  

.AddMoneyModal-add-money-option:hover {
    background: #f0f0f0;
}

.AddMoneyModal-add-money-option img {
    width: 24px;
    height: 24px;
}

.AddMoneyModal-account-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 5em;
}

.AddMoneyModal-account-details p {
    margin: 2px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Graphik Regular';
    font-size: 14px;
}

.AddMoneyModal-account-details .label {
    margin-right: 20px; /* Adjust this value to create space between the label and the value */
}

.AddMoneyModal-account-details span {
    text-align: left; /* Aligns the span text to the left */
    display: inline-block; /* Allows the span to take only the required width */
    min-width: 150px; /* Set a minimum width to align all spans */
    white-space: nowrap; /* Prevent text from wrapping */
    font-family: 'Graphik Regular';
    font-size: 14px;
}

.AddMoneyModal-copy-button {
    display: block;
    margin: 10px auto; /* Center horizontally with auto, adjust top/bottom margins as needed */
    padding: 10px;
    background-color: rgb(131, 14, 202);
    color: white;
    border: none;
    font-family: 'Graphik Medium';
    font-size: 12px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    width: 60%; /* Adjust the width as needed */
    max-width: 200px; /* Optional: Set a maximum width */
    margin-left: 30px; /* Adjust this value to move the button to the left */
    margin-top: 30px; /* Adjust this value to move the button up or down */
}

.AddMoneyModal-copy-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px; /* Adjust this value to move the button up or down */
    margin-bottom: 10px; /* Adjust this value to control the space below the button */
}

.AddMoneyModal-copy-button:hover {
    background-color: rgb(105, 0, 190); /* Darker shade on hover */
}

.AddMoneyModal-copy-button:active {
    background-color: rgb(34, 3, 60); /* Even darker shade on click */
}

.AddMoneyModal-input-wrapper {
    position: relative;
    width: 100%;
}

.AddMoneyModal-modal-body .fund-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.AddMoneyModal-modal-body .fund-card label {
  width: 80%; /* Take 80% of the modal width */
  text-align: left;
  margin-bottom: 5px;
  font-family: 'Graphik Medium';
  font-size: 12px;
}

.AddMoneyModal-modal-body .fund-card input {
    width: 100%; /* Adjust the width as needed */
    max-width: 500px; /* Set a max width to avoid extra width from placeholder */
    font-family: 'Graphik Regular';
    font-size: 14px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center; /* Center the text in the input */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.AddMoneyModal-modal-body .AddMoneyModal-fund-card .AddMoneyModal-continue-button {
    width: 100%; /* Make the button the same width as the input */
    max-width: 400px; /* Set the same max width as the input */
    padding: 12px 20px;
    background-color: rgb(131, 14, 202);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    margin-top: 20px; /* Add some space between the input and the button */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Dashboard.css */

.dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(246, 247, 255);
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
background-color: #fff;
}

.dashboard-logo {
  height: 60px;
  margin-left: 45px;  
}

.dashboard-nav {
  display: flex;
  align-items: center;
  grid-gap: 80px;
  gap: 80px;
  flex-grow: 1; /* Allow nav to grow and fill the available space */
  justify-content: center; /* Center the nav items */
}

.dashboard-nav div {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(150, 150, 150);
  font-weight: 300;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.dashboard-nav img {
  font-family: Inter;
  height: 16px;
margin-right: 6px;
}

.dashboard-profile {
  display: flex;
  align-items: center;
  margin-right: 2.2rem;

}

.profile-icon {
  width: 60px;
  height: 60px;
  background-color: #6a0dad;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
}

.dashboard-main-content {
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1 1;
}

.dashboard-wrapper {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 4rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.dashboard-left {
  flex: 1 1; /* Adjust this value to take more space */
  margin-right: 2rem; /* Maintain margin for spacing */
}
.dashboard-right {
  flex: 1 1;
}

.dashboard-salutation-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.dashboard-salutation {
  display: flex;
  align-items: center;
  font-family: 'Graphik Medium';
  font-size: 12px;
}

.complete-kyc-button {
  background-color: #830eca;
  color: white;
  padding: 0.8rem 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Graphik Medium';
  font-size: 12px;
  margin-top: 1rem;
}

.balance-view-card {
  background-size: cover;
  background-position: center;
  padding: 2rem;
  border-radius: 20px;
  color: rgba(131, 14, 202, 0.72);
  margin: 2rem 0;
  position: relative;
}

.balance-view-card h2 {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgba(131, 14, 202, 0.72);
}

.add-money-button {
  background-color: #ffffff;
  color: #830eca;
  padding: 0.8rem 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  position: relative;
  top: -3.5rem;
  font-family: 'Graphik Medium';
}

.popular-giftcards h3 {
  margin-top: 3rem;
  font-family: 'Graphik-Medium';
  font-weight: 400;
}

.giftcards-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1.5em;
  gap: 1.5em;
}

.giftcard-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(98, 0, 238, 0.03);
  border: solid 0.4px rgba(98, 0, 238, 0.45);
  border-radius: 8px;
  padding: 10px;
  height: 80px;
}

.giftcard-item img {
  width: 80px;
  height: 50px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 4px;
}

.giftcard-item p {
  text-align: center;
  font-size: 8.6px;
  color: rgb(0, 0, 0);
  margin: 0;
  font-family: 'Graphik Regular';
}

.more-giftcards-link {
  display: block;
  margin-top: -2rem;
  margin-bottom: 1em;
  text-align: right;
  text-decoration: none;
  color: #830eca;
}

.recent-transactions h3 {
  margin-bottom: 1rem;
}

.recent-transactions table {
  width: 100%;
  border-collapse: collapse;
}

.recent-transactions th,
.recent-transactions td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.view-all-transactions-link {
  display: block;
  margin-top: -1rem;
  text-align: right;
  text-decoration: none;
  color: #830eca;
}

.recent-transactions {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
}

.recent-transactions table {
  width: 100%;
  border-collapse: collapse;
}
.kyc-warning {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.recent-transactions th {
  padding: 30px;
  text-align: left;
  background-color: rgb(131, 14, 202);
  color: white;
  font-weight: 500;
  font-family: 'Graphik Medium';
  font-size: 20px;
}

.recent-transactions th:first-child {
  border-top-left-radius: 20px;
}

.recent-transactions th:last-child {
  border-top-right-radius: 20px;
}

.recent-transactions td {
  padding: 40px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
  color: #333333;
}

.dashboard-footer {
  text-align: center;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  margin-top: auto;
  font-family: 'Graphik Regular';
  font-size: 14px;
  color: rgb(82, 82, 91);
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .dashboard-nav {
    grid-gap: 40px;
    gap: 40px;
  }

  .dashboard-wrapper {
    flex-direction: column;
  }

  .dashboard-left,
  .dashboard-right {
    margin-right: 4rem;
    margin-bottom: 2rem; /* Added margin for spacing */
  }

  .profile-icon {
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .dashboard-wrapper {
    flex-direction: column;
  }

  .dashboard-left,
  .dashboard-right {
    margin-right: 0;
  }

  .profile-icon {
    width: 25px;
    height: 25px;
  }

  .giftcards-list {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}


.dashboard-nav div.active {
  color: #830ECA;
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 13vw;
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  
  .pagination {
    display: flex;
    align-items: center;
  }
  
  .pagination button {
    background: none;
    border: none;
    color: #000;
    font-size: 1rem;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
    margin: 0 5px;
    transition: background-color 0.3s ease;
  }
  
  .pagination button:hover {
    background-color: #f0f0f0;
  }
  
  .pagination .active {
    background-color: #e5e5ff;
    border: 2px solid #9c9cff;
    color: #000;
  }
  
  .pagination .disabled {
    cursor: not-allowed;
    color: #ccc;
  }
  
  .pagination .dots {
    padding: 5px 10px;
  }
  
*
{
  margin: 0;
}

.maingiftcard-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: rgb(246, 247, 255);
  
}  
  
  .maingiftcard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  background-color: #fff;
  }
  
  .maingiftcard-logo {
    height: 60px;
    margin-left: 45px;  
}

  .maingiftcard-nav {
    display: flex;
    align-items: center;
    grid-gap: 80px;
    gap: 80px;
    flex-grow: 1; /* Allow nav to grow and fill the available space */
    justify-content: center; /* Center the nav items */
  }
  
  .maingiftcard-nav div {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgb(150, 150, 150);
    font-family: Inter;
    font-size: 16px;
    cursor: pointer;
  }
  

  .maingiftcard-nav img {
    font-family: Inter;
    height: 16px;
margin-right: 6px;
  }
  
  .maingiftcard-profile {
    display: flex;
    align-items: center;
    margin-right: 2.2rem;
  
  }
 
  

  .maingiftcard-profile-icon {
    width: 60px;
    height: 60px;
    background-color: #6a0dad;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
  }
  

  .maingiftcard-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding: 0 40px;
  }
  
  .maingiftcard-title {
    flex-grow: 0;
    font-family: 'Space Grotesk';
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin: 0;
    margin-top: 2.5em;

  }
  
  .maingiftcard-search {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 2.5em;
    margin-left: auto;


  }
  
  .search-input {
    font-family: Poppins;
    font-size: 14px;
    color: rgb(57, 57, 57);
    padding: 8px 12px;
    padding-left: 30px; /* Add padding to make space for the icon */
    border-radius: 8px;
    width: 200px;
    background-color: rgb(255, 255, 255);
    border: 0.1px solid white;
    font-family: 'Poppins';
    
  }
  
  .search-icon {
    position: absolute;
    left: 10px;
    color: rgb(150, 150, 150);
    width: 14px;
    height: 12px;
    flex-grow: 0;
    padding: 1.7px 1.5px 1.3px 1.7px;
    font-family: 'Graphik regular';

  }
  
  .placeholder-text {
    position: absolute;
    left: 30px;
    color: rgb(150, 150, 150);
    pointer-events: none;
    transition: opacity 0.3s;
    font-size: 14px;

  }
  
  .placeholder-text.hidden {
    opacity: 0;
  }


  .maingiftcard-categories-wrapper {
    border: 1px solid purple; /* Change border color to purple */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto;
    padding: 5px 20px;
    margin-bottom: 2.5em;
    overflow: hidden; /* Ensures content outside the wrapper is hidden */
    position: relative;
    white-space: nowrap;
    width: 89vw; /* Adjust width to 95% of viewport width */
}

  
.maingiftcard-categories {
  display: flex;
  align-items: center;
  width: 90vw;
  overflow-x: hidden; /* Ensures text doesn't overflow */
  padding-right: 60px; /* Add padding to the right to avoid overlap with the fade effect */
}



  .category-button {
    background: none;
    border: none;
    color: #888;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: normal;
    text-align: left;
    cursor: pointer;
    transition: all 0.3s ease;
    color: rgb(102, 102, 102);
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: visible; /* Allow text to overflow */
    text-overflow: clip; /* Disable ellipsis */
    margin-right: 20px; /* Space between buttons */
}


.category-fade {
  position: absolute;
  right: 40px; /* Adjust this value based on your design */
  top: 0;
  bottom: 0;
  width: 60px; /* Adjust this value based on your design */
  background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0)); /* Fading effect */
  pointer-events: none; /* Allows clicks to pass through */
  z-index: 1; /* Ensure it is above other elements */
}

.category-arrow {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Adjust based on your design */
  background-color: #fff;
  cursor: pointer;
  z-index: 1; /* Ensure it is above other elements */
  color: purple; /* Arrow color purple */
  padding: 10px 0; /* Add padding to top and bottom */
}

.category-arrow::before {
  content: '';
  position: absolute;
  left: -2px; /* Move the line further to the left */
  height: calc(100% - 10px); /* Ensure the line spans the full height minus padding */
  border-left: 1px solid purple; /* Vertical line color and width */
}



  .hidden {
    display: none;
  }
  
  
  .category-button:hover,
  .category-button.active {
    background-color: #830ec8;
    color: white;
    border-radius: 20px;
    padding: 7px 15px;
  }


  .main-giftcards-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 0.5em;
    gap: 0.5em;
    padding: 1em;
    max-width: 95vw; /* Use viewport width instead of percentage */
    margin: 0 auto;
  }
  

  .giftcard-logo {
    text-align: center;
    word-wrap: break-word; /* Ensure long words break to the next line */
    width: 100%;
  }
  .giftcard-logo img {
    max-width: 100%;
    height: auto;
  }
  
  .giftcard-logo p {
    margin-top: -1em;
    font-size: 0.7em;
    color: rgb(102, 102, 102);
    white-space: normal; /* Allow text to wrap to the next line */
    overflow: hidden;
    text-overflow: ellipsis; /* Ellipsis for overflowing text */
    text-align: left;
    font-family: 'Graphik Medium';
  }


  .main-giftcard-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
    width: calc(25% - 1em); /* Adjust the width of the card and the gap */
    height: auto; /* Adjust the height of the card */
    margin-bottom: 1em; /* Space between rows of cards */
}
  
.main-giftcard-item img {
  width: 100%; /* Adjust the width of the image to fill the card */
  height: auto; /* Adjust the height of the image to maintain aspect ratio */
  margin-bottom: 10px;
  background-color: white;
  border-radius: 4px;
}


  .main-pagination-controls {
    display: flex;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 20px;
  }

  
  .main-pagination-controls button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #6a0dad;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .main-pagination-controls button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }


  /*come backhere */

  .maingiftcard-main-content {
    flex: 1 1;
    padding: 20px;
    text-align: center;
  }
  

  

  /* end here*/

  .maingiftcard-footer {
    text-align: center;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    margin-top: auto; /* Ensure the footer sticks to the bottom */
    font-family: 'Graphik Regular';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: rgb(82, 82, 91);
  }

  /* Loading animation styles */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.loader::before,
.loader::after,
.loader div {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    margin: 5px;
    border-radius: 50%;
    background: #fff;
    animation: loader-animation 1.2s infinite ease-in-out;
}
  
.loader::before {
    animation-delay: -0.32s;
}
  
.loader::after {
    animation-delay: -0.16s;
}
  
@keyframes loader-animation {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

.maingiftcard-nav div.active {
  color: #830ECA;
}

.confirm-order-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-order-modal {
  background-color: #fff;
  width: 50vw;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm-order-modal-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

.confirm-order-modal-title {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #830eca;
  margin: 0 auto; /* Center the title */
}

.confirm-order-close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.confirm-order-modal-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.confirm-order-giftcard-image {
  width: 100%;
  display: flex;
  justify-content: center;
}

.confirm-order-giftcard-image img {
  width: 60%;
  height: auto;
  border-radius: 8px;
  margin-top: 2em;
}

.confirm-order-giftcard-details {
  width: 50%;
  margin-top: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
}

.confirm-order-giftcard-details p {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: rgba(29, 29, 29, 0.4); /* Default color */
}

.confirm-order-giftcard-details span {
  color: #000; /* Value color */
}

.confirm-order-pay-now-button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Reduced padding for flatter button */
  border-radius: 8.8px;
  background-color: #830eca;
  color: white;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  margin-bottom: 2.5em;
}

.confirm-order-button-title {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.confirm-order-pay-now-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* SuccessModal.css */
.success-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .success-modal {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 35vw;
    height: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .success-image {
    width: 11em;
    height: 11em;
    margin-bottom: 25px;
  }
  
  .success-title {
    color: rgba(29, 29, 29, 0.4);
    font-size: 12px;
    font-family: 'inter';
    margin-bottom: 10px;
  }
  
  .success-amount {
    color: rgba(29, 29, 29, 1);
    font-size: 30px;
    margin-bottom: 5px;
    font-family: 'Space Grotesk', sans-serif;
  }
  
  .success-bought-for {
    color: rgba(29, 29, 29, 0.4);
    font-size: 12px;
    margin-bottom: 20px;
    font-family: 'inter';

  }
  
  .success-message {
    color: rgba(29, 29, 29, 1);
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .success-message p {
    margin: 20px 0;
  }
  
  .success-back-button {
    padding: 10px 20px;
    background-color: #830ECA;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 300px; /* Adjust the width of the button */
    font-family: 'Inter', sans-serif;
  }
  
  .success-back-button:hover {
    background-color: #5a36a1;
  }
  

  .amount-naira {
    color: rgba(29, 29, 29, 1);
  }
  
.giftcard-purchase-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(246, 247, 255);
}

  
  .giftcardpurchase-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  background-color: #fff;
  }
  
  .giftcardpurchase-logo {
    height: 60px;
    margin-left: 45px;  
  }
  
  .giftcardpurchase-nav {
    display: flex;
    align-items: center;
    grid-gap: 80px;
    gap: 80px;
  }
  
  .giftcardpurchase-nav div {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgb(150, 150, 150);
    font-family: Inter;
    font-size: 16px;
    cursor: pointer;
  }


  .giftcardpurchase-nav img {
    font-family: Inter;
    height: 16px;
    margin-right: 6px;
  }
  
  .giftcardpurchase-profile {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-right: 4.5em; /* Push the profile icon to the left */
  }
  

  .balance-label {
    font-size: 16px;
    color: #5a2d82; /* Adjust this color to match your desired purple */
    font-family: 'Inter';
    margin-bottom: 15px;
  }

  

  .giftcard-profile-icon {
    width: 60px;
    height: 60px;
    background-color: #6a0dad;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
  }
  

  .naira-balance {
    display: block;
    font-size: 14px;
    color: purple;
    margin-top: 10px;
    margin-bottom: 10px;
}

  
  .giftcard-purchase-main-content {
    display: flex;
    flex-direction: row;
    grid-gap: 70px;
    gap: 70px; /* Adjust this value to control the space between the columns */
    width: 100%;
    max-width: 1200px; /* Add this to limit the maximum width */
    justify-content: center; /* Ensure there's space between the columns */
    margin-top: 3rem;
    flex-grow: 1; /* Ensure this part takes up the available space */
}

  
  .giftcard-title {
    font-family: "Space Grotesk";
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .giftcard-image {
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .giftcard-image img {
    width: 300px;
    height: auto;
    border-radius: 10px;
  }
  
  .giftcard-details {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px; /* Add spacing between elements */
}

.giftcard-details h2 {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
}

.giftcard-details p {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    color: #666;
}

.giftcard-details label {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
}

.giftcard-details .amount-options {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px; /* Add spacing between amount buttons */
}

.giftcard-details .amount-button {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
}

.giftcard-details .amount-button.selected {
    background-color: #ccc;
}

.giftcard-details .custom-amount-input {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
}

.giftcard-details .price-display {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
}


.price-display 
{
  width: 100%;
}

.giftcard-details .recipient-radio {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.giftcard-details .recipient-radio input {
    margin-right: 5px;
}



.pay-now-button {
  width: 100%;
  padding: 12px 20px;
  background-color: #6200ea; /* Adjust color if needed */
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.3s;
  box-sizing: border-box;
  border-radius: 5px;

}
.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button-container button {
  flex-grow: 1;
}

.pay-now-button.disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}



.recipient-details {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px; /* Add spacing between input fields */
}

.recipient-details input,
.recipient-details textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
}

.recipient-details input {
    margin-bottom: 10px;
}

.recipient-details textarea {
    resize: none; /* Prevent resizing of the textarea */
}

.recipient-details p {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 14px;
    color: #666;
    margin-top: 5px;
}
  /* end here*/
  /* end here*/

  .GiftcardPurchase-footer {
    text-align: center;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Graphik Regular';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: rgb(82, 82, 91);
    width: 100%; /* Ensure the footer spans the full width */
}

.giftcard-details.extra-margin {
  margin-bottom: 4em; /* Adjust this value as needed */
}


.insufficient-balance-label {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}


.giftcardpurchase-nav div.active {
  color: #830ECA;
}

/* MainTransaction.css */

.MainTransaction-main-transaction-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(246, 247, 255);
}

.MainTransaction-main-transaction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

.MainTransaction-main-transaction-logo {
  height: 60px;
  margin-left: 45px;
}

.MainTransaction-main-transaction-nav {
  display: flex;
  align-items: center;
  grid-gap: 80px;
  gap: 80px;
  flex-grow: 1; /* Allow nav to grow and fill the available space */
  justify-content: center; /* Center the nav items */
}

.MainTransaction-main-transaction-nav div {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(150, 150, 150);
  font-family: Inter;
  font-size: 16px;
  cursor: pointer;
}

.MainTransaction-main-transaction-nav img {
  height: 16px;
  margin-right: 6px;
}

.MainTransaction-main-transaction-profile {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-right: 4.5em;
}

.MainTransaction-profile-icon {
  width: 60px;
  height: 60px;
  background-color: #6a0dad;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: Inter;
  font-size: 20px;
}

.MainTransaction-main-transaction-content {
  flex: 1 1;
  padding: 2rem;
  display: flex;
  justify-content: center; /* Center the content horizontally */
  margin-top: 3rem;

}

.MainTransaction-transaction-wrapper {
  width: 70%; /* Set the table width to 50% */
}

.MainTransaction-transaction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.MainTransaction-transaction-header h2 {
  font-family: 'Space Grotesk';
  font-size: 24px;
  font-weight: bold;
}

.MainTransaction-sort-filter {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.MainTransaction-sort-filter .DateRangePickerInput {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px 10px;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;
}

.MainTransaction-sort-filter .DateRangePickerInput__arrow {
  margin: 0 5px;
}

.MainTransaction-sort-filter .DateRangePickerInput__clear-dates {
  margin-left: 10px;
}

.MainTransaction-transaction-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
  margin-bottom: 3rem;
}

.MainTransaction-transaction-table th,
.MainTransaction-transaction-table td {
  padding: 30px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.MainTransaction-transaction-table th {
  background-color: rgb(131, 14, 202);
  color: white;
  font-weight: 500;
  font-family: 'Graphik Medium';
  font-size: 20px;
}

.MainTransaction-transaction-table th:first-child {
  border-top-left-radius: 20px;
}

.MainTransaction-transaction-table th:last-child {
  border-top-right-radius: 20px; /* Fixed from border-top-left-radius */
}

.MainTransaction-transaction-table td {
  color: #333333;
}

.MainTransaction-transaction-table td.negative {
  color: #F01616;
}

.MainTransaction-transaction-table td.positive {
  color: #029664;
}

.MainTransaction-main-transaction-footer {
  text-align: center;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Graphik Regular';
  font-size: 14px;
  color: rgb(82, 82, 91);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  background-color: #fff;
  color: #830eca;
  border: 1px solid #830eca;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-family: 'Inter';
  font-size: 14px;
}

.page-button.active {
  background-color: #830eca;
  color: #fff;
}

.page-button:hover {
  background-color: #d3d3d3;
}


.transaction-view-button {
  background: none;
  border: none;
  color: #830eca;
  cursor: pointer;
  font-family: 'Inter';
  font-size: 14px;
}

.MainTransaction-transaction-table .transaction-view-button {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: #830eca;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Inter';
  font-size: 14px;
}

.MainTransaction-transaction-table .transaction-view-button:hover {
  color: #6a0dad;
}

.MainTransaction-main-transaction-nav div.active {
  color: #830ECA;
}

/* customDateRangePicker.css */
.DateRangePicker {
    width: 100%;
  }
  
  .DateRangePickerInput {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 3px 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .DateRangePickerInput__arrow {
    margin: 0 10px;
  }
  
  .DateRangePickerInput__clear-dates {
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .DateInput_input {
    font-family: 'Inter';
    font-size: 14px;
    color: #333;
    background-color: transparent;
    height: 30px;
  }
  
  .DayPicker {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
  }
  
  .DayPicker_weekHeader {
    color: #333;
  }
  
  .DayPickerNavigation_button {
    border: none;
    background: none;
    color: #830eca;
    cursor: pointer;
  }
  
  .CalendarMonth_caption {
    font-family: 'Inter';
    font-size: 14px;
    color: #333;
  }
  
  .DayPicker_weekHeader {
    font-family: 'Inter';
    font-size: 14px;
    color: #333;
  }
  
  .DateRangePicker_picker {
    z-index: 1000;
    position: absolute;
    top: 50px; /* Adjust based on your design */
    left: 50%;
    transform: translateX(-50%);
  }
  
  .DateRangePicker_picker__rtl {
    direction: rtl;
  }
  
  .MainTransaction-date-range-modal {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    padding: 20px;
  }
  
  .MainTransaction-date-range-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .MainTransaction-date-range-actions button {
    background-color: #830eca;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: 'Inter';
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .MainTransaction-date-range-input {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 8px; /* Adjust the border-radius to match the rounded corners */
    padding: 10px 15px; /* Adjust padding for a balanced look */
    font-family: 'Inter';
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; /* Ensure the button takes up the full width of its container */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05); /* Add subtle shadow for depth */
  }
  
  .MainTransaction-date-range-input span {
    margin-right: 10px; /* Add some space between the text and the arrow */
  }
  
  .MainTransaction-date-range-input::after {
    content: '▾'; /* Use a down arrow symbol */
    font-size: 12px;
    color: #333; /* Match the text color */
    margin-left: 5px; /* Add 5px space */
  }
  
  .DateRangePicker-wrapper {
    position: relative;
  }
  
  .DateRangePicker-overlay {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    background: white;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .DateRangePicker-close-btn {
    position: absolute;
    top: 0.1rem;
    right: 5px;
    background: #fff; /* White background */
    color: #830eca; /* Purple color */
    border: 1px solid #830eca;
    border-radius: 50%; /* Make the button round */
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    cursor: pointer;
  }
  
  .DateRangePicker-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .DateRangePicker-buttons button {
    background-color: #830eca;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: 'Inter';
    font-size: 14px;
    cursor: pointer;
  }
  
  .DateRangePicker-buttons button:hover {
    background-color: #6a0dad;
  }
  
  .DateRangePicker-buttons .clear-btn {
    background-color: #fff;
    color: #830eca;
    border: 1px solid #830eca;
  }
  
  .DateRangePicker-buttons .clear-btn:hover {
    background-color: #d3d3d3;

  }
  
.transaction-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.transaction-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transaction-modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
}

.transaction-download-receipt-btn {
  display: block;
  background-color: #830eca;
  color: white;
  border: none;
  padding: 12px 40px; /* Adjust padding to make the button wider */
  border-radius: 5px;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;
  margin: 35px auto 0; /* Center the button and add margin-top */
  text-align: center;
  margin-bottom: 35px;
}

.transaction-download-receipt-btn img {
  margin-left: 5px;
}


.transaction-view-button {
  background: none;
  border: none;
  color: #830eca;
  cursor: pointer;
  font-family: 'Inter';
  font-size: 12px;
}

.transaction-modal-content h2 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 25px;
  color: #830eca;
  text-align: center;
  margin-bottom: 25px;
}

.transaction-modal-content {
  width: 100%; /* Ensure full width of modal */
  max-width: 560px; /* Max width to ensure it fits within the modal */
  padding: 20px; /* Add padding for better spacing */
  box-sizing: border-box; /* Include padding in the width calculation */
  line-height: 2.6; /* Set line height as per your requirement */
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}


.transaction-modal-content p {
  display: flex;
  justify-content: space-between;
  margin: 5px 0; /* Reduces the space between each paragraph */
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.transaction-modal strong {
  font-weight: normal;
  white-space: nowrap; 
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}

.transaction-reference {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}

.transaction-reference-number {
  margin-right: 10px;
}

.transaction-modal .status {
  font-weight: normal;
  font-size: 14px; 
  display: inline-block;
  box-shadow: none;
  border: none;
}

.transaction-modal .status.successful {
  color: green;
}

.transaction-modal .status.failed {
  color: red;
}
.Profile-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(246, 247, 255);
}

.Profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

.Profile-logo {
  height: 60px;
  margin-left: 45px;
}

.Profile-nav {
  display: flex;
  align-items: center;
  grid-gap: 80px;
  gap: 80px;
  flex-grow: 1; /* Allow nav to grow and fill the available space */
  justify-content: center; /* Center the nav items */
}

.Profile-nav img {
  height: 16px;
  margin-right: 6px;
}

.Profile-nav div {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(150, 150, 150);
  font-family: Inter;
  font-size: 16px;
  cursor: pointer;
}

.Profile-profile {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-right: 4.5em;
}

.Profile-profile-icon {
  width: 60px;
  height: 60px;
  background-color: #6a0dad;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: Inter;
  font-size: 20px;
}

.Profile-content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Profile-wrapper {
  width: 80%;
  max-width: 1000px;
  margin: 20px 0;
}




.Profile-wrapper h1{
  margin-top: 2rem;
font-family: 'Space Grotesk';
font-size: 24px;

}



.Profile-wrapper h2 h3{
font-family: 'Inter';
font-size: 20px;

}


.Profile-wrapper p{
  font-family: 'Inter';
  font-size: 14px;
  
  }


  .Profile-verified {
    display: flex;
    align-items: center;
    background-color: #f3e9ff; /* Light purple background */
    border-radius: 12px;
    padding: 4px 8px;
    font-weight: bold;
    margin-left: 8px;
  }
  
  .Profile-verified-badge {
    margin-left: 4px;
    width: 16px; /* Adjust the size as needed */
    height: 16px; /* Adjust the size as needed */
  }
  

  .Profile-overview {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Adjust the alignment */
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin-top: 2rem;
  }
  
  .Profile-avatar {
    width: 100px; /* Adjust the size as needed */
    height: 100px; /* Adjust the size as needed */
    background-color: lightgray;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 30px;
    margin-right: 20px;
    position: relative;
  }



.Profile-avatar-placeholder {
  color: #666;
}
.Profile-avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.Profile-avatar-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}



.Profile-overview-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto; /* Push the buttons to the right */
}

.Profile-overview-details h2 {
  margin: 0;
  font-size: 20px;
  display: flex;
  align-items: center;
  font-family: 'Inter';
}
.Profile-verified {
  background: rgba(131, 14, 202, 0.10);
  color: #830ECA;
  font-size: 14px;
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  font-style: normal;
font-weight: 400;
font-family: Inter;

}

.Profile-overview-details p {
  margin: 5px 0;
  color: #666;
  font-family: 'Inter';
  font-size: 14px;


}


.Profile-button {
  background-color: #fff; /* Change to white background */
  color: #830eca;
  border: 2px solid #830eca; /* Add border */
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  margin-top: 10px;
}

.Profile-change-password-button {
  background-color: #830eca;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;
  align-self: center; /* Center vertically in the parent */
  margin-left: 1rem;
}

/* Profile.css */

/* Personal Information Section */
.Profile-section {
  background-color: #ffffff; /* Set background color to white */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Inner padding for spacing */
  margin-bottom: 20px; /* Space between sections */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.Profile-section h3 {
  margin-bottom: 15px; /* Space below the title */
  color: #000;
font-family: "Space Grotesk";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.Profile-section p {
  margin: 10px 0; /* Margin around each row */
  color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.Profile-section p strong {
  display: block; /* Display as block to stack the labels */
  font-family: Inter;
  color: #969696;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Profile-section p:not(:last-child) {
  margin-bottom: 20px; /* Space between rows */
}


.Profile-section p span {
  display: block; /* Block for label value to ensure alignment */
  margin-left: 0; /* No margin needed */
  font-weight: normal; /* Normal weight for values */
  color: #000; /* Black color for values */
}

/* Flexbox for layout */
.Profile-section .Profile-row {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: space-between; /* Space between columns */
}

.Profile-section .Profile-column {
  flex: 0 1 48%; /* Flex grow each column equally, minimum width 48% */
  padding: 10px 0; /* Padding for each column */
}

.Profile-section .Profile-column strong {
  display: block; /* Block display for labels */
  margin-bottom: 5px; /* Space between label and value */
}
/* Wrapper for absolute positioning */
.Profile-copy-button-wrapper {
  position: relative; /* Set the wrapper to relative positioning */
  height: 0; /* Ensure the wrapper doesn't affect layout */
}

.Profile-copy-button {
  display: inline-block; /* Keep display inline-block for other buttons */
  padding: 10px 20px; /* Button padding */
  font-family: 'Inter';
  font-size: 14px;
 color: #FFFFFF; /* Button text color */
  border-radius: 5px; /* Rounded corners */
  background: #830ECA; /* Button background color */
  cursor: pointer; /* Pointer cursor */
  text-align: center; /* Center text */
  transition: background 0.3s ease, color 0.3s ease; /* Smooth transitions */
  position: absolute; /* Set the button to absolute positioning */
  right: 0; /* Align to the right */
  top: -13rem; /* Adjust as necessary for alignment */
}

.Profile-copy-button:hover {
  background-color: #6a0dad; /* Background on hover */
  color: #fff; /* Text color on hover */
}


.Profile-delete-button {
  display: block;
  margin: 0 auto; /* Center horizontally */
  padding: 10px 20px; /* Button padding */
  font-size: 16px; /* Button text size */
  font-family: Inter;
  font-style: normal;
font-weight: 600;
line-height: normal;
  color: #FF0000; /* Button text color */
  border: none; /* Remove border */
  background: none; /* Transparent background */
  cursor: pointer; /* Pointer cursor */
  text-align: center; /* Center text */
  position: relative;
  bottom: 1rem; /* 1rem away from the bottom of the parent */
  transform: translateY(50%);
  margin-bottom: 3rem;
}



.Profile-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Graphik Regular';
  font-size: 14px;
  color: rgb(82, 82, 91);
}


.Profile-logout-button {
  background-color: #6a1b9a;
  color: white;
  border: none;
  padding: 10px 50px; /* Adjust this padding to make the button longer */  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 20px 0; /* Adjust margin as needed */
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}


.Profile-logout-button:hover {
  color: darkred;
}
.unique-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .unique-modal-container {
    background: white;
    border-radius: 8px;
    width: 450px;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
  }
  
  .unique-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Space Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #830ECA;
    margin-bottom: 20px;
    position: relative;
    padding: 20px 0; /* Add space at the top and bottom */
}

  .unique-modal-header h2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
   

  }

  .unique-modal-close-button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

  .unique-modal-body {
    margin-top: 20px;
  }
  


  .unique-modal-input-wrapper {
    position: relative;
    margin-bottom: 15px;

  }



  .unique-modal-input-group label {
    display: block;
    margin-bottom: 5px;
    color: #2B2B2B;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
  }
  

  
  .unique-modal-input-wrapper input {
        width: 100%;
    padding: 10px;
    border: 1.094px solid #D8D8D8;
    border-radius: 4px;
    font-family: Inter;
    font-size: 14px;  
    padding-right: 40px; /* Add space for visibility toggle button */
    box-sizing: border-box;
}


.unique-modal-visibility-toggle {
    background: none;
    border: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1rem;
  }
  .unique-modal-success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }
  
  .unique-modal-success-image {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  .unique-modal-success-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .unique-modal-success-content p {
    font-size: 16px;
    color: #666;
  }
  

  .unique-modal-change-password-button {
    width: 100%;
    padding: 10px;
    background: #830ECA;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #FFF;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
line-height: 100%; /* 17.506px */
  }
  
  .unique-modal-change-password-button:hover {
    background: #6a0dad;
  }
  
/* Background overlay for the modal */
.delete-account-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 999; /* Ensure it is below the modal */
  }
  
  .delete-account-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px; /* Adjust width */
    height: 250px; /* Adjust height */
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure it overlays other elements */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .delete-account-modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .delete-account-close-button {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 20px;
    cursor: pointer;
  }
  


  .delete-account-title {
    color: #000;
    text-align: center;
    font-family: "Space Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px; /* Add margin bottom */
  }
  
  .delete-account-button {
    color: #969696;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.5px; /* Adjust line-height */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px; /* Add margin to space buttons */
  }
  
  .delete-account-button-cancel {
    background: #FFF;
    color: #969696;
border: 1px solid #969696;
  }
  
  .delete-account-button-delete {
    background: #F01616;
    color: #fff;
  }
  
  .delete-account-button-delete:hover {
    background: #e04e4e;
  }
  
  .delete-account-modal-content > div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px; /* Add margin top */
  }
  
