/* MainTransaction.css */

.MainTransaction-main-transaction-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(246, 247, 255);
}

.MainTransaction-main-transaction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

.MainTransaction-main-transaction-logo {
  height: 60px;
  margin-left: 45px;
}

.MainTransaction-main-transaction-nav {
  display: flex;
  align-items: center;
  gap: 80px;
  flex-grow: 1; /* Allow nav to grow and fill the available space */
  justify-content: center; /* Center the nav items */
}

.MainTransaction-main-transaction-nav div {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(150, 150, 150);
  font-family: Inter;
  font-size: 16px;
  cursor: pointer;
}

.MainTransaction-main-transaction-nav img {
  height: 16px;
  margin-right: 6px;
}

.MainTransaction-main-transaction-profile {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 4.5em;
}

.MainTransaction-profile-icon {
  width: 60px;
  height: 60px;
  background-color: #6a0dad;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: Inter;
  font-size: 20px;
}

.MainTransaction-main-transaction-content {
  flex: 1;
  padding: 2rem;
  display: flex;
  justify-content: center; /* Center the content horizontally */
  margin-top: 3rem;

}

.MainTransaction-transaction-wrapper {
  width: 70%; /* Set the table width to 50% */
}

.MainTransaction-transaction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.MainTransaction-transaction-header h2 {
  font-family: 'Space Grotesk';
  font-size: 24px;
  font-weight: bold;
}

.MainTransaction-sort-filter {
  display: flex;
  gap: 10px;
  align-items: center;
}

.MainTransaction-sort-filter .DateRangePickerInput {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px 10px;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;
}

.MainTransaction-sort-filter .DateRangePickerInput__arrow {
  margin: 0 5px;
}

.MainTransaction-sort-filter .DateRangePickerInput__clear-dates {
  margin-left: 10px;
}

.MainTransaction-transaction-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
  margin-bottom: 3rem;
}

.MainTransaction-transaction-table th,
.MainTransaction-transaction-table td {
  padding: 30px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.MainTransaction-transaction-table th {
  background-color: rgb(131, 14, 202);
  color: white;
  font-weight: 500;
  font-family: 'Graphik Medium';
  font-size: 20px;
}

.MainTransaction-transaction-table th:first-child {
  border-top-left-radius: 20px;
}

.MainTransaction-transaction-table th:last-child {
  border-top-right-radius: 20px; /* Fixed from border-top-left-radius */
}

.MainTransaction-transaction-table td {
  color: #333333;
}

.MainTransaction-transaction-table td.negative {
  color: #F01616;
}

.MainTransaction-transaction-table td.positive {
  color: #029664;
}

.MainTransaction-main-transaction-footer {
  text-align: center;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Graphik Regular';
  font-size: 14px;
  color: rgb(82, 82, 91);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  background-color: #fff;
  color: #830eca;
  border: 1px solid #830eca;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-family: 'Inter';
  font-size: 14px;
}

.page-button.active {
  background-color: #830eca;
  color: #fff;
}

.page-button:hover {
  background-color: #d3d3d3;
}


.transaction-view-button {
  background: none;
  border: none;
  color: #830eca;
  cursor: pointer;
  font-family: 'Inter';
  font-size: 14px;
}

.MainTransaction-transaction-table .transaction-view-button {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #830eca;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Inter';
  font-size: 14px;
}

.MainTransaction-transaction-table .transaction-view-button:hover {
  color: #6a0dad;
}

.MainTransaction-main-transaction-nav div.active {
  color: #830ECA;
}
