
/* Modal styles */
.signup-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
  }
  
  /* Add this to signup-modal.css */
.signup-resend-button {
  background: none;
  border: none;
  color: #830eca;
  cursor: pointer;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  padding: 0;
}



  .signup-modal-content {
    position: relative;
  
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    max-width: 600px;
    width: 80%;
    text-align: center;
    font-family: 'Graphik Medium', sans-serif;
  }
  
  .signup-close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-size: 24px;
    color: #333; /* Adjust color as needed */
  
  }
  
  .signup-success-image {
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    margin-bottom: 15px;
  }
  
  .signup-modal-title {
  
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;  
    margin-bottom: 20px;
    font-family: Graphik Semibold;
    font-size: 32px;
    font-weight: 600;  color: rgb(131, 14, 202);
  
  }
  
  .signup-modal-text {
    margin-bottom: 20px;
    color: #393939;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }
  
  .signup-modal-text a {
    text-decoration: none;
    color: #830ECA;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: none;
  }
  
  .signup-modal-text a:hover {
    text-decoration: underline;
  }
  
  .signup-modal-footer {
    color: #393939;
    text-decoration: none;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: none;
  }
  
  .signup-modal-footer a {
    text-decoration: none;
    color: #830ECA;
  font-family: Graphik Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: none;
  }
  
  .resend-button-custom {
  text-decoration: none;
  color: #830ECA;
  font-family:  'Inter';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: none;
  background: none;
  border: none;
  cursor: pointer;
}

  
.resend-button-custom:disabled {
  cursor: not-allowed;
}
