.NairaInput-container {
    position: relative;
    width: 100%;
  }
  
  .NairaInput-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Graphik Regular', sans-serif;
    text-align: left;
    padding-left: 40px; /* Add padding to leave space for the Naira symbol */
    box-sizing: border-box; /* Ensure padding and border are included in width */
  }
  
  .NairaInput-input::placeholder {
    color: #ccc;
    font-family: 'Graphik Regular', sans-serif;
  }
  
  .NairaInput-container::before {
    content: '₦';
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #000;
    font-family: 'Graphik Regular', sans-serif;
  }
  