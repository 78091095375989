@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Graphik:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');



*
{
  margin: 0;
}

.maingiftcard-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: rgb(246, 247, 255);
  
}  
  
  .maingiftcard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  background-color: #fff;
  }
  
  .maingiftcard-logo {
    height: 60px;
    margin-left: 45px;  
}

  .maingiftcard-nav {
    display: flex;
    align-items: center;
    gap: 80px;
    flex-grow: 1; /* Allow nav to grow and fill the available space */
    justify-content: center; /* Center the nav items */
  }
  
  .maingiftcard-nav div {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgb(150, 150, 150);
    font-family: Inter;
    font-size: 16px;
    cursor: pointer;
  }
  

  .maingiftcard-nav img {
    font-family: Inter;
    height: 16px;
margin-right: 6px;
  }
  
  .maingiftcard-profile {
    display: flex;
    align-items: center;
    margin-right: 2.2rem;
  
  }
 
  

  .maingiftcard-profile-icon {
    width: 60px;
    height: 60px;
    background-color: #6a0dad;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
  }
  

  .maingiftcard-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding: 0 40px;
  }
  
  .maingiftcard-title {
    flex-grow: 0;
    font-family: 'Space Grotesk';
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin: 0;
    margin-top: 2.5em;

  }
  
  .maingiftcard-search {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 2.5em;
    margin-left: auto;


  }
  
  .search-input {
    font-family: Poppins;
    font-size: 14px;
    color: rgb(57, 57, 57);
    padding: 8px 12px;
    padding-left: 30px; /* Add padding to make space for the icon */
    border-radius: 8px;
    width: 200px;
    background-color: rgb(255, 255, 255);
    border: 0.1px solid white;
    font-family: 'Poppins';
    
  }
  
  .search-icon {
    position: absolute;
    left: 10px;
    color: rgb(150, 150, 150);
    width: 14px;
    height: 12px;
    flex-grow: 0;
    padding: 1.7px 1.5px 1.3px 1.7px;
    font-family: 'Graphik regular';

  }
  
  .placeholder-text {
    position: absolute;
    left: 30px;
    color: rgb(150, 150, 150);
    pointer-events: none;
    transition: opacity 0.3s;
    font-size: 14px;

  }
  
  .placeholder-text.hidden {
    opacity: 0;
  }


  .maingiftcard-categories-wrapper {
    border: 1px solid purple; /* Change border color to purple */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto;
    padding: 5px 20px;
    margin-bottom: 2.5em;
    overflow: hidden; /* Ensures content outside the wrapper is hidden */
    position: relative;
    white-space: nowrap;
    width: 89vw; /* Adjust width to 95% of viewport width */
}

  
.maingiftcard-categories {
  display: flex;
  align-items: center;
  width: 90vw;
  overflow-x: hidden; /* Ensures text doesn't overflow */
  padding-right: 60px; /* Add padding to the right to avoid overlap with the fade effect */
}



  .category-button {
    background: none;
    border: none;
    color: #888;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: normal;
    text-align: left;
    cursor: pointer;
    transition: all 0.3s ease;
    color: rgb(102, 102, 102);
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: visible; /* Allow text to overflow */
    text-overflow: clip; /* Disable ellipsis */
    margin-right: 20px; /* Space between buttons */
}


.category-fade {
  position: absolute;
  right: 40px; /* Adjust this value based on your design */
  top: 0;
  bottom: 0;
  width: 60px; /* Adjust this value based on your design */
  background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0)); /* Fading effect */
  pointer-events: none; /* Allows clicks to pass through */
  z-index: 1; /* Ensure it is above other elements */
}

.category-arrow {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Adjust based on your design */
  background-color: #fff;
  cursor: pointer;
  z-index: 1; /* Ensure it is above other elements */
  color: purple; /* Arrow color purple */
  padding: 10px 0; /* Add padding to top and bottom */
}

.category-arrow::before {
  content: '';
  position: absolute;
  left: -2px; /* Move the line further to the left */
  height: calc(100% - 10px); /* Ensure the line spans the full height minus padding */
  border-left: 1px solid purple; /* Vertical line color and width */
}



  .hidden {
    display: none;
  }
  
  
  .category-button:hover,
  .category-button.active {
    background-color: #830ec8;
    color: white;
    border-radius: 20px;
    padding: 7px 15px;
  }


  .main-giftcards-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5em;
    padding: 1em;
    max-width: 95vw; /* Use viewport width instead of percentage */
    margin: 0 auto;
  }
  

  .giftcard-logo {
    text-align: center;
    word-wrap: break-word; /* Ensure long words break to the next line */
    width: 100%;
  }
  .giftcard-logo img {
    max-width: 100%;
    height: auto;
  }
  
  .giftcard-logo p {
    margin-top: -1em;
    font-size: 0.7em;
    color: rgb(102, 102, 102);
    white-space: normal; /* Allow text to wrap to the next line */
    overflow: hidden;
    text-overflow: ellipsis; /* Ellipsis for overflowing text */
    text-align: left;
    font-family: 'Graphik Medium';
  }


  .main-giftcard-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
    width: calc(25% - 1em); /* Adjust the width of the card and the gap */
    height: auto; /* Adjust the height of the card */
    margin-bottom: 1em; /* Space between rows of cards */
}
  
.main-giftcard-item img {
  width: 100%; /* Adjust the width of the image to fill the card */
  height: auto; /* Adjust the height of the image to maintain aspect ratio */
  margin-bottom: 10px;
  background-color: white;
  border-radius: 4px;
}


  .main-pagination-controls {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }

  
  .main-pagination-controls button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #6a0dad;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .main-pagination-controls button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }


  /*come backhere */

  .maingiftcard-main-content {
    flex: 1;
    padding: 20px;
    text-align: center;
  }
  

  

  /* end here*/

  .maingiftcard-footer {
    text-align: center;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    margin-top: auto; /* Ensure the footer sticks to the bottom */
    font-family: 'Graphik Regular';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: rgb(82, 82, 91);
  }

  /* Loading animation styles */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.loader::before,
.loader::after,
.loader div {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    margin: 5px;
    border-radius: 50%;
    background: #fff;
    animation: loader-animation 1.2s infinite ease-in-out;
}
  
.loader::before {
    animation-delay: -0.32s;
}
  
.loader::after {
    animation-delay: -0.16s;
}
  
@keyframes loader-animation {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

.maingiftcard-nav div.active {
  color: #830ECA;
}
