/* Dashboard.css */

.dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(246, 247, 255);
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
background-color: #fff;
}

.dashboard-logo {
  height: 60px;
  margin-left: 45px;  
}

.dashboard-nav {
  display: flex;
  align-items: center;
  gap: 80px;
  flex-grow: 1; /* Allow nav to grow and fill the available space */
  justify-content: center; /* Center the nav items */
}

.dashboard-nav div {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(150, 150, 150);
  font-weight: 300;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.dashboard-nav img {
  font-family: Inter;
  height: 16px;
margin-right: 6px;
}

.dashboard-profile {
  display: flex;
  align-items: center;
  margin-right: 2.2rem;

}

.profile-icon {
  width: 60px;
  height: 60px;
  background-color: #6a0dad;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
}

.dashboard-main-content {
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1;
}

.dashboard-wrapper {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 4rem;
  gap: 2rem;
}

.dashboard-left {
  flex: 1; /* Adjust this value to take more space */
  margin-right: 2rem; /* Maintain margin for spacing */
}
.dashboard-right {
  flex: 1;
}

.dashboard-salutation-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.dashboard-salutation {
  display: flex;
  align-items: center;
  font-family: 'Graphik Medium';
  font-size: 12px;
}

.complete-kyc-button {
  background-color: #830eca;
  color: white;
  padding: 0.8rem 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Graphik Medium';
  font-size: 12px;
  margin-top: 1rem;
}

.balance-view-card {
  background-size: cover;
  background-position: center;
  padding: 2rem;
  border-radius: 20px;
  color: rgba(131, 14, 202, 0.72);
  margin: 2rem 0;
  position: relative;
}

.balance-view-card h2 {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgba(131, 14, 202, 0.72);
}

.add-money-button {
  background-color: #ffffff;
  color: #830eca;
  padding: 0.8rem 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  position: relative;
  top: -3.5rem;
  font-family: 'Graphik Medium';
}

.popular-giftcards h3 {
  margin-top: 3rem;
  font-family: 'Graphik-Medium';
  font-weight: 400;
}

.giftcards-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1.5em;
}

.giftcard-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(98, 0, 238, 0.03);
  border: solid 0.4px rgba(98, 0, 238, 0.45);
  border-radius: 8px;
  padding: 10px;
  height: 80px;
}

.giftcard-item img {
  width: 80px;
  height: 50px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 4px;
}

.giftcard-item p {
  text-align: center;
  font-size: 8.6px;
  color: rgb(0, 0, 0);
  margin: 0;
  font-family: 'Graphik Regular';
}

.more-giftcards-link {
  display: block;
  margin-top: -2rem;
  margin-bottom: 1em;
  text-align: right;
  text-decoration: none;
  color: #830eca;
}

.recent-transactions h3 {
  margin-bottom: 1rem;
}

.recent-transactions table {
  width: 100%;
  border-collapse: collapse;
}

.recent-transactions th,
.recent-transactions td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.view-all-transactions-link {
  display: block;
  margin-top: -1rem;
  text-align: right;
  text-decoration: none;
  color: #830eca;
}

.recent-transactions {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
}

.recent-transactions table {
  width: 100%;
  border-collapse: collapse;
}
.kyc-warning {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.recent-transactions th {
  padding: 30px;
  text-align: left;
  background-color: rgb(131, 14, 202);
  color: white;
  font-weight: 500;
  font-family: 'Graphik Medium';
  font-size: 20px;
}

.recent-transactions th:first-child {
  border-top-left-radius: 20px;
}

.recent-transactions th:last-child {
  border-top-right-radius: 20px;
}

.recent-transactions td {
  padding: 40px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
  color: #333333;
}

.dashboard-footer {
  text-align: center;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  margin-top: auto;
  font-family: 'Graphik Regular';
  font-size: 14px;
  color: rgb(82, 82, 91);
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .dashboard-nav {
    gap: 40px;
  }

  .dashboard-wrapper {
    flex-direction: column;
  }

  .dashboard-left,
  .dashboard-right {
    margin-right: 4rem;
    margin-bottom: 2rem; /* Added margin for spacing */
  }

  .profile-icon {
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .dashboard-wrapper {
    flex-direction: column;
  }

  .dashboard-left,
  .dashboard-right {
    margin-right: 0;
  }

  .profile-icon {
    width: 25px;
    height: 25px;
  }

  .giftcards-list {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}


.dashboard-nav div.active {
  color: #830ECA;
}
