.giftcard-purchase-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(246, 247, 255);
}

  
  .giftcardpurchase-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  background-color: #fff;
  }
  
  .giftcardpurchase-logo {
    height: 60px;
    margin-left: 45px;  
  }
  
  .giftcardpurchase-nav {
    display: flex;
    align-items: center;
    gap: 80px;
  }
  
  .giftcardpurchase-nav div {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgb(150, 150, 150);
    font-family: Inter;
    font-size: 16px;
    cursor: pointer;
  }


  .giftcardpurchase-nav img {
    font-family: Inter;
    height: 16px;
    margin-right: 6px;
  }
  
  .giftcardpurchase-profile {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 4.5em; /* Push the profile icon to the left */
  }
  

  .balance-label {
    font-size: 16px;
    color: #5a2d82; /* Adjust this color to match your desired purple */
    font-family: 'Inter';
    margin-bottom: 15px;
  }

  

  .giftcard-profile-icon {
    width: 60px;
    height: 60px;
    background-color: #6a0dad;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
  }
  

  .naira-balance {
    display: block;
    font-size: 14px;
    color: purple;
    margin-top: 10px;
    margin-bottom: 10px;
}

  
  .giftcard-purchase-main-content {
    display: flex;
    flex-direction: row;
    gap: 70px; /* Adjust this value to control the space between the columns */
    width: 100%;
    max-width: 1200px; /* Add this to limit the maximum width */
    justify-content: center; /* Ensure there's space between the columns */
    margin-top: 3rem;
    flex-grow: 1; /* Ensure this part takes up the available space */
}

  
  .giftcard-title {
    font-family: "Space Grotesk";
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .giftcard-image {
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .giftcard-image img {
    width: 300px;
    height: auto;
    border-radius: 10px;
  }
  
  .giftcard-details {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Add spacing between elements */
}

.giftcard-details h2 {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
}

.giftcard-details p {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    color: #666;
}

.giftcard-details label {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
}

.giftcard-details .amount-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Add spacing between amount buttons */
}

.giftcard-details .amount-button {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
}

.giftcard-details .amount-button.selected {
    background-color: #ccc;
}

.giftcard-details .custom-amount-input {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
}

.giftcard-details .price-display {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
}


.price-display 
{
  width: 100%;
}

.giftcard-details .recipient-radio {
    display: flex;
    align-items: center;
    gap: 10px;
}

.giftcard-details .recipient-radio input {
    margin-right: 5px;
}



.pay-now-button {
  width: 100%;
  padding: 12px 20px;
  background-color: #6200ea; /* Adjust color if needed */
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.3s;
  box-sizing: border-box;
  border-radius: 5px;

}
.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button-container button {
  flex-grow: 1;
}

.pay-now-button.disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}



.recipient-details {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Add spacing between input fields */
}

.recipient-details input,
.recipient-details textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
}

.recipient-details input {
    margin-bottom: 10px;
}

.recipient-details textarea {
    resize: none; /* Prevent resizing of the textarea */
}

.recipient-details p {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 14px;
    color: #666;
    margin-top: 5px;
}
  /* end here*/
  /* end here*/

  .GiftcardPurchase-footer {
    text-align: center;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Graphik Regular';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: rgb(82, 82, 91);
    width: 100%; /* Ensure the footer spans the full width */
}

.giftcard-details.extra-margin {
  margin-bottom: 4em; /* Adjust this value as needed */
}


.insufficient-balance-label {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}


.giftcardpurchase-nav div.active {
  color: #830ECA;
}
