.transaction-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.transaction-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transaction-modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
}

.transaction-download-receipt-btn {
  display: block;
  background-color: #830eca;
  color: white;
  border: none;
  padding: 12px 40px; /* Adjust padding to make the button wider */
  border-radius: 5px;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;
  margin: 35px auto 0; /* Center the button and add margin-top */
  text-align: center;
  margin-bottom: 35px;
}

.transaction-download-receipt-btn img {
  margin-left: 5px;
}


.transaction-view-button {
  background: none;
  border: none;
  color: #830eca;
  cursor: pointer;
  font-family: 'Inter';
  font-size: 12px;
}

.transaction-modal-content h2 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 25px;
  color: #830eca;
  text-align: center;
  margin-bottom: 25px;
}

.transaction-modal-content {
  width: 100%; /* Ensure full width of modal */
  max-width: 560px; /* Max width to ensure it fits within the modal */
  padding: 20px; /* Add padding for better spacing */
  box-sizing: border-box; /* Include padding in the width calculation */
  line-height: 2.6; /* Set line height as per your requirement */
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}


.transaction-modal-content p {
  display: flex;
  justify-content: space-between;
  margin: 5px 0; /* Reduces the space between each paragraph */
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.transaction-modal strong {
  font-weight: normal;
  white-space: nowrap; 
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}

.transaction-reference {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}

.transaction-reference-number {
  margin-right: 10px;
}

.transaction-modal .status {
  font-weight: normal;
  font-size: 14px; 
  display: inline-block;
  box-shadow: none;
  border: none;
}

.transaction-modal .status.successful {
  color: green;
}

.transaction-modal .status.failed {
  color: red;
}