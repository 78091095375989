.confirm-order-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-order-modal {
  background-color: #fff;
  width: 50vw;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm-order-modal-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

.confirm-order-modal-title {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #830eca;
  margin: 0 auto; /* Center the title */
}

.confirm-order-close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.confirm-order-modal-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.confirm-order-giftcard-image {
  width: 100%;
  display: flex;
  justify-content: center;
}

.confirm-order-giftcard-image img {
  width: 60%;
  height: auto;
  border-radius: 8px;
  margin-top: 2em;
}

.confirm-order-giftcard-details {
  width: 50%;
  margin-top: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.confirm-order-giftcard-details p {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: rgba(29, 29, 29, 0.4); /* Default color */
}

.confirm-order-giftcard-details span {
  color: #000; /* Value color */
}

.confirm-order-pay-now-button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Reduced padding for flatter button */
  border-radius: 8.8px;
  background-color: #830eca;
  color: white;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  margin-bottom: 2.5em;
}

.confirm-order-button-title {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.confirm-order-pay-now-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
