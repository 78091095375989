@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Graphik:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



/* Tailwind CSS Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles */
body {
  max-width: 100%;
  margin: 0 auto; /* Center align the content */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Prevent horizontal overflow */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Background Image for Header and Hero Section */
.header-hero-bg {
  background: url('../src/assets/hero-bg-img.png') no-repeat center center;
  background-size: cover;
  padding: 0 5.006em;
  width: 100%;
  height: 100vh; /* Extend to full viewport height */
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
  justify-content: center; /* Center content vertically */
}




/* Custom Class for Gift Cards Image */
.gift-cards-img {
  width: 29.56em;
  height: 31.56em;
  object-fit: contain;
  position: absolute;
  right: 80%; /* Adjust as needed */
  top: 13%; /* Center vertically */
  z-index: 10; /* Ensure it's above other elements */
  animation: rotate-clockwise 10s linear infinite; /* Animation properties */
}

/* Keyframes for Rotation Animation */
@keyframes rotate-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Style for Logo Image */
.logo {
  width: 8.0em;
  height: 3.5em;
  position: relative; /* Allows you to adjust the position */
  top: -5em; /* Adjust the value to move the logo upwards */
  margin-top: 0; /* Remove the default margin-top */
}

/* Base styles */
.header-buttons-container {
  display: flex;
  gap: 1rem;
  position: absolute;
  top: 1.5rem; /* Adjust the value as needed */
  right: 2rem; /* Adjust the value as needed */
}


.header-button {
  margin-left: 1rem; /* Space between buttons */
  padding: 0.5rem 1rem;
  border-radius: 0.5em; /* Fully rounded corners */
  font-family: 'Graphik-Regular', sans-serif; /* Font family */
  font-size: 0.9rem; /* Font size */
  cursor: pointer; /* Pointer cursor */
}

.header-button-signin {
  background-color: transparent;
  color: white; /* White text color */
  border: 2px solid white; /* White border */
}

.header-button-getstarted {
  background-color: #830eca; /* Purple background */
  color: white; /* White text color */
  border: 2px solid #830eca;
}

.header-button-signin:hover,
.header-button-getstarted:hover {
  opacity: 0.7; /* Slightly transparent on hover */
}


/* Style for Gift Heading */
.gift-heading {
  margin-top: 1em;
  width: 13.25em;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 3.32em;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  color: #ffffff;
}


/* Style for Gift Description */
.gift-description {
  width: 37em; 
  font-family: 'Inter', sans-serif;
  font-size: 1.25em; 
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  color: #ffffff;
  margin-bottom:4.375em; /* Adjust the value as needed */
}

/* Custom Style for Button */
.custom-button {
  width: 14.875em;
  height: 3.875em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.625em;
  padding: 1.5em 2.9375em;
  border-radius: 0.625em;
  background-color: rgb(255, 184, 0);
  border: none; /* No border color */
  margin-top: 0.625em;
}

/* Custom Style for Button Text */
.button-text {
  width: 6.125em; /* 98px */
  height: 1.5em; /* 24px */
  font-family: 'Graphik-Medium';
  font-size: 1.25em; /* 20px */
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: #830eca;
}

/* CSS for HowItWorks section */
.how-it-works-section {
  padding-top: 5em; /* 80px */
  padding-bottom: 5em; /* 80px */
  background-color: #f7fafc; /* Light gray background */
}

.how-it-works-container {
  max-width: 112rem; /* 1792px */
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  text-align: center;
  width: 80%;
}

.how-it-works-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 2em; /* 32px */
}

.how-it-works-title {
  width: 14.0625em; /* 225px */
  height: 2.875em; /* 46px */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2.25em; /* 36px */
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: #830eca;
  position: relative; /* Position relative to enable absolute positioning of pseudo-elements */
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: center; /* Center content horizontally */
}

.how-it-works-title::before,
.how-it-works-title::after {
  content: "";
  flex: 1; /* Adjust this value to change the line length */
  height: 4px; /* Adjust height as needed */
  background-color: #830eca; /* Same color as text */
  margin: 0 1em; /* Adjust spacing as needed */
}

.how-it-works-title::before {
  margin-right: 1em; /* Adjust spacing on the left */
}

.how-it-works-title::after {
  margin-left: 1em; /* Adjust spacing on the right */
}

.how-it-works-steps {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.5em; /* 24px */
}

.how-it-works-step {
  width: calc(20% - 1.5em); /* Adjusted width to fit four items in a row */
  max-width: 300px; /* Maximum width for each card */
  background-color: #ffffff; /* White background for the cards */
  padding: 1.5em; /* 24px padding */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow */
  border-radius: 0.75em; /* 12px rounded corners */
  text-align: left;
}

.step-number {
  width: 3.55em; /* 56.8px */
  height: 1.5em; /* 31.8px */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.4625em; /* 7.4px */
  padding: 0.4625em; /* 7.4px */
  border-radius: 0.4625em; /* 7.4px */
  background-color: rgba(131, 14, 202, 0.1);
  font-family: 'Inter', sans-serif;
  font-size: 0.875em; /* 14px */
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  color: #830eca;
}

.step-icon {
  width: 3.4375em; /* 55px */
  height: 3.4375em; /* 55px */
  margin-top: 1em;
  margin-bottom: 1em; /* Adjust margin as needed */
}

.verified-message {
  color: #029664;
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px;
font-family: 'Inter', sans-serif;
  animation: fadeOut 2s forwards;

}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.step-title {
  width: 15.125em; /* 242px */
  height: 2.375em; /* 38px */
  font-family: 'Inter', sans-serif;
  font-size: 1em; /* 16px */
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  color: #000000;
  margin-bottom: 0.5em; /* Adjust margin as needed */
}

.step-description {
  width: 13.9875em; /* 223.7px */
  height: 3.1875em; /* 51px */
  font-family: 'Inter', sans-serif;
  font-size: 0.875em; /* 14px */
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  color: #6c6c6c;
  line-height: 1.5em; /* Adjust line-height as needed */
  margin-bottom: 2em;
}


/* CSS for PopularCategories section */
.popular-categories-section {
  margin-left: auto; /* Center align the content */
  margin-right: auto; /* Center align the content */
  width: 80%;
  height: auto;
  background-color: rgba(255, 184, 0, 0.03);
  padding: 5em 0; /* Adjust padding as needed */
}

.popular-categories-container {
  max-width: 112rem; /* 1792px */
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  text-align: center;
  position: relative;
}

.popular-categories-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 2em; /* 32px */
}

.popular-categories-title {
  width: 20.6875em; /* 331px */
  height: 2.875em; /* 46px */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2.25em; /* 36px */
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: #830eca;
  position: relative; /* Position relative to enable absolute positioning of pseudo-elements */
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: center; /* Center content horizontally */
}

.popular-categories-title::before,
.popular-categories-title::after {
  content: "";
  flex: 0.3; /* Adjust this value to change the line length */
  height: 4px; /* Adjust height as needed */
  background-color: #830eca; /* Same color as text */
  margin: 0 1em; /* Adjust spacing as needed */
}

.popular-categories-title::before {
  margin-right: 1em; /* Adjust spacing on the left */
}

.popular-categories-title::after {
  margin-left: 1em; /* Adjust spacing on the right */
}

.popular-categories-cards {
  display: flex;
  justify-content: center; /* Center align the content */
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1em;
}

.category-card {
  width: calc(50% - 1em); /* Two items per row by default */
  min-width: 150px; /* Minimum width to prevent too much compression */
  max-width: 250px; /* Maximum width to ensure they don't get too large */
  height: auto; /* Adjust height to be automatic */
  background-color: #ffffff;
  border-radius: 0.925em; /* 14.8px */
  padding: 1em; /* 16px */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.category-icon {
  width: 8em;
  height: 8em;
}

.category-title {
  font-family: 'Graphik-Regular', sans-serif;
  font-size: 1.125em; /* 18px */
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: #000000;
  margin-top: 1.5em; /* Adjust margin as needed */
  white-space: normal; /* Allow text to wrap */
  overflow: hidden;
  text-overflow: ellipsis;
}

/* CSS for WhyChooseUs section */
.why-choose-us-section {
  padding-top: 5em; /* 80px */
  padding-bottom: 5em; /* 80px */
  background-color: #f7fafc; /* Light gray background */
}

.why-choose-us-container {
  max-width: 80vw; /* 80% of the viewport width */
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  text-align: center;
}

.why-choose-us-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0.2em; /* 32px */
}

.why-choose-us-title {
  width: 16.5625em; /* 265px */
  height: 2.875em; /* 46px */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2.25em; /* 36px */
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: #830eca;
  position: relative; /* Position relative to enable absolute positioning of pseudo-elements */
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: center; /* Center content horizontally */
}

.why-choose-us-title::before,
.why-choose-us-title::after {
  content: "";
  flex: 0.5; /* Adjust this value to change the line length */
  height: 4px; /* Adjust height as needed */
  background-color: #830eca; /* Same color as text */
  margin: 0 1em; /* Adjust spacing as needed */
}

.why-choose-us-title::before {
  margin-right: 1em; /* Adjust spacing on the left */
}

.why-choose-us-title::after {
  margin-left: 1em; /* Adjust spacing on the right */
}

.why-choose-us-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 2em; /* Adjust gap as needed */
  width: 60vw; /* Adjust width as needed */
  margin: 0 auto; /* Center the grid */
}

.image-container {
  width: 100%; /* Make it responsive */
  height: 100%; /* Make it responsive */
}

.wide-range {
  grid-column: 1 / span 2; /* Span across column 1 and 2 */
  grid-row: 1; /* Only in row 1 */
  border-radius: 1.975em; /* 31.6px */
  overflow: hidden; /* Ensure the image respects the border-radius */
}

.easy-payments {
  grid-column: 1;
  grid-row: 2;
  border-radius: 2.325em; /* 37.2px */
  overflow: hidden; /* Ensure the image respects the border-radius */
}

.digital-delivery {
  grid-column: 2;
  grid-row: 2;
  border-radius: 2.325em; /* 37.2px */
  overflow: hidden; /* Ensure the image respects the border-radius */
}

.customer-support {
  grid-column: 3;
  grid-row: 1 / span 2; /* Span across row 1 and 2 */
  border-radius: 2.29375em; /* 36.7px */
  overflow: hidden; /* Ensure the image respects the border-radius */
}

/* Ensuring images fit within their containers */
.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container without distortion */
  border-radius: inherit; /* Inherit the border-radius from the parent */
}


/*css styling for FAQ  */

.faq-section {
  padding-top: 5em;
  padding-bottom: 5em;
}

.faq-container {
  max-width: 112rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

.faq-title {
  flex-grow: 0;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #830eca;
  margin-bottom: 3em;
  position: relative; /* Position relative to enable absolute positioning of pseudo-elements */
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: center; /* Center content horizontally */
}

.faq-title::before,
.faq-title::after {
  content: "";
  flex: 0.1; /* Adjust this value to change the line length */
  height: 4px; /* Adjust height as needed */
  background-color: #830eca; /* Same color as text */
  margin: 0 1em; /* Adjust spacing as needed */
}

.faq-title::before {
  margin-right: 1em; /* Adjust spacing on the left */
}

.faq-title::after {
  margin-left: 1em; /* Adjust spacing on the right */
}





.faq-list {

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em; /* Adjust gap as needed */
  
}



.faq-card {
  width: 90vw; /* Take 90% of the viewport width */
  border-radius: 31.4pt 31.4pt 0 0 ; /* Border radius */
  margin: 0 auto; /* Center the card */
  padding: 2em 0 6em 0; /* Adjust padding as needed */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  transition: max-height 0.4s ease, padding 0.4s ease; /* Smooth transition for hover effect */
  overflow: hidden; /* Hide overflowing content */
  max-height: 4em; /* Initial height */
}







.faq-card .faq-question {
  width: 100%; /* Ensure the question takes the full width */
  text-align: left; /* Align text to the left */
  padding: 0.5emem; /* Padding to prevent text from touching the edge */
}

.faq-card:nth-child(1) {
  background-color: #830eca;
}

.faq-card:nth-child(2) {
  background-color: #ffb800;
}

.faq-card:nth-child(3) {
  background-color: #6200ee;
}

.faq-card:nth-child(4) {
  background-color: #010101;
}

.faq-card:nth-child(5) {
  background-color: #f05e16;

}

.faq-card:hover {
  max-height: 80%; /* Expand to fit content */
  padding: 3em 0 15em 0; /* Adjust padding when expanded */
}


.faq-question {
  font-size: 1.7em; /* Adjust font size as needed */
  font-family: 'Graphik-Medium'; /* Specify the font-family */
  color: #fff; /* Default text color */
  margin: 0; /* Remove default margin */
  text-align: left; /* Align text to the left */
  padding-left: 2.5em; /* Padding to prevent text from touching the edge */
  padding-top: 4em;
  width: 100%; /* Ensure the question takes the full width */
}


.faq-answer {
  font-family: 'Graphik-Medium';
  font-size: 1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;     
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding-left: 4em;
  padding-top: 4em;
}

.faq-card:nth-child(2) .faq-question {
  color: #000; /* Black font color for the second question */
}

.faq-card:nth-child(2) .faq-answer {
  color: #000; /* Black font color for the second question */
}


.faq-card:nth-child(1) {
  margin-top: -5em; /* Adjust to create the overlap */
}
.faq-card:nth-child(2) {
  margin-top: -5em; /* Adjust to create the overlap */
}

.faq-card:nth-child(3) {
  margin-top: -5em; /* Adjust to create the overlap */
}

.faq-card:nth-child(4) {
  margin-top: -5em; /* Adjust to create the overlap */
}
.faq-card:nth-child(5) {
  margin-top: -5em; /* Adjust to create the overlap */
  border-radius: 31.4pt 31.4pt 31.4pt 31.4pt;
  padding: 3em 0 15em 0; /* Adjust padding when expanded */

}


.customer-reviews-section {
  padding: 10em 0; /* 80px */
  background-color: #f7fafc; /* Light gray background */
  text-align: center;
}

.customer-reviews-title {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2.25em; /* 36px */
  font-weight: bold;
  color: #830eca;
  position: relative; /* Position relative to enable absolute positioning of pseudo-elements */
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: center; /* Center content horizontally */
}


.customer-reviews-title::before,
.customer-reviews-title::after {
  content: "";
  flex: 0.1; /* Adjust this value to change the line length */
  height: 4px; /* Adjust height as needed */
  background-color: #830eca; /* Same color as text */
  margin: 0 1em; /* Adjust spacing as needed */
}


.customer-reviews-title::before {
  margin-right: 1em; /* Adjust spacing on the left */
}

.customer-reviews-title::after {
  margin-left: 1em; /* Adjust spacing on the right */
}



.customer-review {
  width: 80vw; /* Take 90% of the viewport width */
  height: 55vh; /* Take 50% of the viewport height */
  background-size: cover;
  background-position: center;
  border-radius: 31.6px;
  padding: 1.5em;
  position: relative;
  text-align: left;
  margin: 0 auto; /* Center the review */
}
.customer-review-text {
  color: white;
  font-family: 'Space Grotesk', sans-serif;

  font-size: 2.813em; /* 24px */
  position: absolute;
  top: 10%;
  left: 5%;
  width: 70%;
  transform: translateY(-30%);
}

.review-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.prev-button,
.next-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 2em; /* Adjust the value as needed for spacing */
}

.prev-button img,
.next-button img {
  width: 40px;
  height: 40px;

}

.cta-section {
  padding: 10em 0;
  background-color: #f7fafc; /* Light gray background */
  position: relative;
  overflow: hidden;
  text-align: center;
}


/* Gradient Blur Class */
.gradient-blur {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: linear-gradient(to bottom, #dba3fd, #830eca);
  opacity: 0.24;
  filter: blur(120px);
  z-index: 0;
}



.cta-container {
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  width: 80vw; /* Take 70% of the viewport width */
  margin: 0 auto; /* Center the card */
  padding: 2em; /* Adjust padding as needed */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.3em;
}



.cta-title {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2.75em; /* 44px */
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: #0c0e0f;
  margin-bottom: 0.5em; /* Adjust margin as needed */
}

.cta-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 1.27em; /* 20.3px */
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  color: #0c0e0f;
  margin-bottom: 2em; /* Adjust margin as needed */
}


.cta-button-container {
  display: flex;
  justify-content: center;
}


.cta-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  border-radius: 6px;
  background-color: #830eca;
  color: white;
  font-family: 'Graphik-Regular', sans-serif;
  font-size: 1em; /* Adjust font size as needed */
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
  margin-top: 0em;
  margin-bottom: 5em;
}

.cta-button:hover {
  background-color: #6d0fb7; /* Darker shade for hover effect */
}

/* Styling for Footer */
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem; /* Adjust as needed */
  padding-bottom: 1.5rem; /* Add padding to move the line down */
  border-bottom: 1px solid #e5e7eb; /* Grey line */
}

.footer-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


.footer-logo {
    display: flex;

  height: 2.1rem; /* 10em */
  margin-right: auto; /* Move logo to the left */
  position: relative;
  top: 2.5rem; /* Adjust this value to move the logo downwards */
  margin-bottom: 1rem; /* Add space below the logo */

  left: 7.5rem;
}


.footer-links {
  display: flex;
  flex-direction: row; /* Keep links in a row */
  justify-content: center;
}


.footer-link:last-child {
  margin-right: 0; /* Remove margin for the last element */
}


.footer-link {
  margin-right: 2rem; /* Adjust spacing as needed */
  font-family: 'Graphik', sans-serif; /* Add this line */
  font-weight: 500;
  color: #0c0e0f;
  text-decoration: none;
  transition: color 0.3s ease;

}


.footer-link:hover {
  color: #000000;
}

.footer-social {
  display: flex;
  gap: 2rem; /* 4em */
  margin-left: auto; /* Move logo to the left */
  position: relative;
  top: -2.1rem; /* Adjust this value to move the logo downwards */
  right: 8.5rem;
  flex-direction: row; /* Keep social icons in a row */
  justify-content: center;

}



.social-link {
  color: #0c0e0f;
  transition: color 0.3s ease;
  font-size: 1.25rem; /* Add this line for adjusting the font size */

}


.social-link {
  margin-right: 1rem; /* Adjust spacing as needed */
}

.social-link:last-child {
  margin-right: 0; /* Remove margin for the last element */
}

.social-link:hover {
  color: #000000;
}

.footer-bottom {
  display: flex;
  justify-content: space-evenly;
  text-align: left;
  margin-top: 1.5rem; /* 6em */
}

.footer-bottom p {
  margin: 0.5em 0;
  flex: 1; /* Allow p elements to take available space */
}

.footer-bottom p:first-child {
  text-align: left;
  margin-left: 7.5rem;
}

.footer-bottom p:last-child {
  text-align: right;
  margin-right: 8.5rem;


}

.footer-bottom {
  color: #4a4a4a; /* Dark grey color */
}/* SignUp page styles */





/* Styling s for the signup.js page*/


.signup-container-custom {
  display: flex;
  height: 75vh;
  width: 65vw;
  margin: auto;
  border-radius: 8px; /* optional: adds rounded corners */
  overflow: hidden; /* optional: ensures children do not overflow */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.signup-left-custom {
  width: 40%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: #ffffff;
}

.signup-right-custom {
  width: 60%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signup-logo {
  width: 15em; /* Adjust the logo size as needed */
  height: auto; /* Maintain aspect ratio */
}

.signup-title-custom {
  font-size: 1.1rem;
  margin-left: 1.2rem;
  font-family: Space Grotesk;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgb(39, 39, 39);
  margin-top: 1em;
  
}

.signup-form-custom {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.form-group-custom {
  margin-bottom: 1rem;
}

.form-group-custom label {
  display: block;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
  font-family: Graphik Medium;

  
}

.form-group-custom input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  font-family: Graphik Medium;

}

.signup-button-custom {
  width: 107%; /* Make the button the same width as the form */
  padding: 15px;
  background-color: rgb(131, 14, 202);  
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  font-family: Graphik Medium;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.signup-button-custom:hover {
  background-color: #574bff; /* Adjust to your brand color */
}

.signup-link-custom {
  display: block;
  text-align: left;
  font-size: 0.9rem;
  color: #2b2b2b; /* Adjust to your brand color */
  text-decoration: none;
  margin-left: 2rem;
}
.signup-link-custom:hover {
  text-decoration: none;
}

.signup-link-custom a {
  color: #830eca; /* Adjust to your brand color */
  text-decoration: none;
}

.signup-link-custom span {
  text-decoration: none;
}

.signup-link-custom a:hover {
  text-decoration: none;
}

.logo-title {
  font-size: 1.5rem; /* Adjust this value to make the logo title smaller */
  margin-top: 1rem;
}

.logo-description {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.form-group-inline {
  display: flex;
  justify-content: space-between;
  gap: 3rem; /* Adjust this value to set the desired gap */
}



.form-group-inline .form-group-custom {
  flex: 1; /* Adjust the width of each inline form group */
}
/* Additional styles for password visibility toggle */
.password-container {
  position: relative;
  width: 100%;
}

.password-container input {
  width: calc(101% - 5px); /* Subtract the space needed for the toggle icon */
}

.password-toggle-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
  font-size: 0.9rem;
}



/* Styling for SignIn.js page*/

.signin-container-custom {
  display: flex;
  height: 60vh;
  width: 50vw;
  margin: auto;
  border-radius: 8px; /* optional: adds rounded corners */
  overflow: hidden; /* optional: ensures children do not overflow */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.signin-left-custom {
  width: 40%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: #ffffff;
}

.signin-right-custom {
  width: 60%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signin-logo {
  width: 15em; /* Adjust the logo size as needed */
  height: auto; /* Maintain aspect ratio */
}


.signin-title-custom {
  font-size: 1.5rem; /* Initial font size for the title */
  margin-top: 0; /* Reset any previous margin */
  top: 0; /* Reset any previous top value */
  font-family: Space Grotesk;
  margin-left: 1.1rem; /* Move the title to the right */



}



/*

.signin-title-custom {
  font-size: 1.2rem;
  margin-left: 1rem;
  font-family: Space Grotesk;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgb(39, 39, 39);
  position: absolute;
  top: 1rem;
}
form-group-custom


*/
.signin-form-custom {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  margin-top: 2rem; /* Add margin to push it down */
}


.form-group-custom {
  margin-bottom: 1rem;
}


.form-group-custom label {
  display: block;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
  font-family: Graphik Medium;
}

.form-group-custom input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  font-family: Graphik Medium;
}

.signin-button-custom {
  width: 107%; /* Make the button the same width as the form */
  padding: 15px;
  background-color: #830eca;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  font-family: Graphik Medium;
  transition: background-color 0.3s ease;
}

.signin-button-custom:hover {
  background-color: #574bff; /* Adjust to your brand color */
}

.signin-link-custom {
  display: block;
  text-align: left;
  font-size: 0.9rem;
  color: #2b2b2b; /* Adjust to your brand color */
  text-decoration: none;
  margin-top: 1rem; /* Adjust this value to position it closer to the button */
  font-family: Graphik Medium;


}

.forgot-password-link {
  text-align: left;
  margin-top: 2rem; /* Adjust this value to move it around */
}

.forgot-password-link a {
  color: #830eca; /* Adjust to your brand color */
  text-decoration: underline;
  font-family: Graphik Medium;
}

.forgot-password-link a:hover {
  text-decoration: underline;
}




.signin-link-custom:hover {
  text-decoration: none;
}

.signin-link-custom a {
  color: #830eca; /* Adjust to your brand color */
  text-decoration: none;
}

.signin-link-custom span {
  text-decoration: none;
}

.signin-link-custom a:hover {
  text-decoration: none;
}

.logo-title {
  font-size: 1.5rem; /* Adjust this value to make the logo title smaller */
  margin-top: 1rem;
}

.logo-description {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.password-container {
  position: relative;
  width: 100%;
}

.password-container {
  position: relative;
  width: 100%;
}

.password-container input {
  width: calc(101% - 5px); /* Subtract the space needed for the toggle icon */
}
.password-toggle-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
  font-size: 0.9rem;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  text-align: left;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}


.forgot-password-link {
  text-align: left;
  font-size: 0.9rem;
  color: #830eca; /* Adjust to your brand color */
  text-decoration: none;
  margin-bottom: 1rem; /* Add margin to position above the submit button */
}

.forgot-password-link:hover {
  text-decoration: underline;
}


/* Styling for ResetPassword.js page */
.reset-password-container-custom {
  display: flex;
  height: 60vh;
  width: 50vw;
  margin: auto;
  border-radius: 8px; /* optional: adds rounded corners */
  overflow: hidden; /* optional: ensures children do not overflow */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.reset-password-left-custom {
  width: 40%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: #ffffff;
}

.reset-password-right-custom {
  width: 60%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reset-password-logo {
  width: 15em; /* Adjust the logo size as needed */
  height: auto; /* Maintain aspect ratio */
}

.reset-password-title-custom {
  font-size: 1.2rem;
  margin-left: 1.5rem;
  font-family: Space Grotesk;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgb(39, 39, 39);
  position: absolute;
  top: 4rem;
}

.reset-password-form-custom {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.form-group-custom {
  margin-bottom: 1rem;
}

.form-group-custom label {
  display: block;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
  font-family: Inter;
}

.form-group-custom input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  font-family: Inter;
}





.reset-password-button-custom {
  width: 107%; /* Make the button the same width as the form */
  padding: 15px;
  background-color: rgb(131, 14, 202);  
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  font-family: Graphik Medium;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.reset-password-button-custom:hover {
  background-color: #574bff; /* Adjust to your brand color */
}

.back-to-login-link {
  display: block;
  text-align: left;
  font-size: 0.9rem;
  color: #2b2b2b; /* Adjust to your brand color */
  text-decoration: none;
  margin-left: 2rem;
}

.back-to-login-link:hover {
  text-decoration: none;
}

.back-to-login-link a {
  color: #830eca; /* Adjust to your brand color */
  text-decoration: none;
}

.back-to-login-link span {
  text-decoration: none;
}

.back-to-login-link a:hover {
  text-decoration: none;
}

.password-container {
  position: relative;
  width: 100%;
}

.password-container input {
  width: calc(101% - 5px); /* Subtract the space needed for the toggle icon */
}

.password-toggle-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
  font-size: 0.9rem;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  text-align: left;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.success-message {
  color: green;
  font-size: 0.9rem;
  text-align: left;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}


/* Reset Password Modal styles */
.reset-password-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
}

.reset-password-modal-content {
  position: relative;
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  width: 80%;
  text-align: center;
  font-family: 'Graphik Medium', sans-serif;
}

.reset-password-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  font-size: 24px;
  color: #333; /* Adjust color as needed */
}

.reset-password-success-image {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  margin-bottom: 15px;
}

.reset-password-modal-title {
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  margin-bottom: 20px;
  font-family: Graphik Semibold;
  font-size: 32px;
  font-weight: 600;
  color: rgb(131, 14, 202);
}

.reset-password-modal-text {
  margin-bottom: 20px;
  color: rgb(57, 57, 57);
  font-family: Graphik Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-50px); }
  to { transform: translateY(0); }
}

/* Global transition styles */
.page-transition-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.page-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.page-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.page-transition-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}




/* Media Queries */

/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .header-hero-bg {
    padding: 0 6em;
    height: 70vh; /* Adjust this value as needed */

  }

  .logo {
      width: 13.31em;
      height: 6em;
      top: -4em; /* Adjust the value to move the logo upwards */
      left: -3em;

  }

  .gift-cards-img {
  display: none;
  }

  .gift-heading {
    font-size: 3.5em;
    position: relative;
    top: -1em; /* Adjust the value to move the logo upwards */
    left: -1em;
  }

  .gift-description {
    font-size: 1.4em;
    width: 30em;
    position: relative;
    top: -2.5em; /* Adjust the value to move the logo upwards */
    left: -2.5em;
  }

  .custom-button {
    padding: 1.6em 3em;
    position: relative;
    top: -3.5em; /* Adjust the value to move the logo upwards */
    left: -4.3em;
  }

  .button-text {
    font-size: 1.3em;
  }


  .header-buttons-container {
    position: absolute;
    top: 4rem; /* Adjust this value to position the buttons correctly */
    right: 1rem; /* Adjust this value to position the buttons correctly */
    flex-direction: row; /* Stack buttons vertically on smaller screens */
    align-items: flex-end;
  }

  .how-it-works-step {
    width: calc(40% - 1.5em); /* Two items per row */
  }
  .popular-categories-cards {
    justify-content: space-around; /* Ensure cards are spaced evenly */
  }

  .category-card {
    width: calc(50% - 1em); /* Two items per row */
  }


  .why-choose-us-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    grid-template-rows: auto auto auto; /* Three rows */
    gap: 1em; /* Adjust gap as needed */
    width: 50vw; /* Slightly smaller grid */
  }

  .wide-range {
    grid-column: 1 / span 2; /* Span across both columns */
    grid-row: 1; /* First row */
    border-radius: 1.975em; /* 31.6px */
    overflow: hidden; /* Ensure the image respects the border-radius */
  }

  .easy-payments {
    grid-column: 1; /* First column */
    grid-row: 2; /* Second row */
    border-radius: 2.325em; /* 37.2px */
    overflow: hidden; /* Ensure the image respects the border-radius */
  }

  .digital-delivery {
    grid-column: 2; /* Second column */
    grid-row: 2; /* Second row */
    border-radius: 2.325em; /* 37.2px */
    overflow: hidden; /* Ensure the image respects the border-radius */
  }

  .customer-support {
    grid-column: 1 / span 2; /* Span across both columns */
    grid-row: 3; /* Third row */
    width: 100%;
    height: auto; /* Adjust height as needed */
  }

  .customer-support .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image fits within the container */
  }


  .customer-reviews-section {
    padding: 5em 0; /* Adjust padding as needed */
  }

  .customer-reviews-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em; /* Adjust margin for better spacing */
  }

  .customer-reviews-title {
    font-size: 1.5em; /* Reduce font size for better readability on smaller screens */
  }


  .customer-review {
    width: 90vw; /* Adjust width to fit better on smaller screens */
    height: 40vh; /* Set a fixed height */
    background-size: contain; /* Ensure the background image scales properly */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent background image from repeating */
    border-radius: 31.6px;
    padding: 1.5em;
    position: relative;
    text-align: left;
    margin: 0 auto; /* Center the review */
  }


  .customer-review-text {
    color: white;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 1.5em; /* Reduce font size for better readability on smaller screens */
    position: absolute;
    top: 20%; /* Center vertically */
    left: 5%;
    width: 90%;
    transform: translateY(-50%); /* Adjust to center text vertically */
  }


  .review-navigation {
    margin-top: 0.5em; /* Adjust margin for better spacing */
  }
  .footer-top {
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
  }

 

  .footer-logo {
    display: flex;
    justify-content: center;
    margin: 0 auto 1.5rem auto; /* Center logo and add space below */
    position: relative;
    left: 0; /* Reset left position */
    top: 0; /* Reset top position */
  }

  .footer-links {
    display: flex;
    justify-content: center;
    gap: 1rem; /* Adjust spacing as needed */
    margin-bottom: 1rem; /* Add space below links */
    font-size: 0.75em;

  }

  .footer-social {
    display: flex;
    justify-content: center;
    gap: 1rem; /* Adjust spacing as needed */
    margin-top: 1rem; /* Add space above social icons */
    position: relative;
    left: 0; /* Reset left position */
    right: 0; /* Reset right position */
    top: 0; /* Reset top position */
    margin-left: auto; /* Remove left margin auto */
    margin-right: auto; /* Remove right margin auto */
    font-size: 0.5em;

  }
 
  .footer-bottom p {
    margin-left: 5rem;  
  }

  .footer-bottom {
    margin-bottom: 2em;
    font-size: 0.8em;

  }

  .signin-title-custom {
    font-size: 0.5rem; /* Smaller font size for medium screens */
    top: 0; /* Reset any previous top value */
  }

  .signin-form-custom {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-top: 2rem; /* Add margin to push it down */
  }
  
  .form-group-custom {
    margin-bottom: 1rem;
  }




    }
    




/* Laptops and Desktops */
@media (min-width: 1024px) and (max-width: 1439px) {
  .header-hero-bg {
    padding: 0 8em;
    height: 80vh; /* Adjust this value as needed */

  }

    .forgot-password-container-custom {
      height: 60vh;
      width: 60vw;
  
    }



  .signin-title-custom {
    font-size: 0.5rem; /* Adjusted font size */
    margin-top: -3rem; /* Adjusted margin for vertical positioning */
    margin-left: 3.5rem; /* Move the title to the right */
  }

  .signin-form-custom {
    margin-top: 2rem; /* Maintain the margin-top for spacing */
    margin-left: 2rem; /* Move the form to the right */
  }

  .form-group-custom {
    margin-top: 1rem; /* Increase the margin for form groups */
  }

  .logo {
    width: 10.31em;
    height: 2.3em;
    top: -12em; /* Adjust the value to move the logo upwards */
    left: -5em;
    margin-top: 0; /* Remove the default margin-top */
  }

  .header-buttons-container {
    position: absolute;
    top: 4rem; /* Adjust the value as needed */
    right: 2rem; /* Adjust the value as needed */
  }
  

  .gift-cards-img {
  display: none;
  }

  .gift-heading {
    font-size: 4em;
    position: relative;
    top: -2em; /* Adjust the value to move the logo upwards */
    left: -1.3em;
  }

  .gift-description {
    font-size: 1.5em;
    width: 38em;
    position: relative;
    top: -5em; /* Adjust the value to move the logo upwards */
    left: -3.2em;
  }

  .custom-button {
    padding: 1.8em 3.2em;
    position: relative;
    top: -6.5em; /* Adjust the value to move the logo upwards */
    left: -6em;
  }

  .button-text {
    font-size: 1.4em;
  }


  .why-choose-us-grid {
    display: grid;
    grid-template-rows: auto auto auto; /* Three rows */
    gap: 1em; /* Adjust gap as needed */
    width: 80vw; /* Slightly smaller grid */
  }

  .popular-categories-cards {
    justify-content: space-around; /* Ensure cards are spaced evenly */
  }
  
  .wide-range, .easy-payments, .digital-delivery {
    width: 100%; /* Make grid items full width on small screens */
    height: auto; /* Adjust height as needed */
  }


  .customer-support .card-image {
    width: 105%;
    height: 100%;
    object-fit: cover; /* Ensure the image fits within the container */
  }

    .how-it-works-steps {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Two items per row */
      gap: 2rem; /* Add gap between the grid items */
      justify-content: center; /* Center the grid items */
      width: 70%; /* Adjust the width of the grid container */
      margin: 0 auto; /* Center the grid container */
    }
  
    .how-it-works-step {
      max-width: 500px; /* Maximum width for each card */
      min-width: 300px; /* Minimum width for each card */
    
  }
  


  .customer-reviews-section {
    padding: 5em 0; /* Adjust padding as needed */
  }

  .customer-reviews-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em; /* Adjust margin for better spacing */
  }

  .customer-reviews-title {
    font-size: 1.75em; /* Adjust font size for better readability */
  }

  .customer-review {
    width: 85vw; /* Adjust width to fit better on larger screens */
    height: 45vh; /* Set a fixed height */
    background-size: contain; /* Ensure the background image scales properly */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent background image from repeating */
    border-radius: 31.6px;
    padding: 1.5em;
    position: relative;
    text-align: left;
    margin: 0 auto; /* Center the review */
  }

  .customer-review-text {
    color: white;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 1.75em; /* Adjust font size for better readability */
    position: absolute;
    top: 20%; /* Center vertically */
    left: 5%;
    width: 90%;
    transform: translateY(-50%); /* Adjust to center text vertically */
  }



  .footer-top {
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
  }

  .footer-logo {
    display: flex;
    justify-content: center;
    margin: 0 auto 1.5rem auto; /* Center logo and add space below */
    position: relative;
    left: 0; /* Reset left position */
    top: 0; /* Reset top position */
  }

  .footer-links {
    display: flex;
    justify-content: center;
    gap: 1rem; /* Adjust spacing as needed */
    margin-bottom: 1rem; /* Add space below links */
    font-size: 0.75em;
  }

  .footer-social {
    display: flex;
    justify-content: center;
    gap: 1rem; /* Adjust spacing as needed */
    margin-top: 1rem; /* Add space above social icons */
    position: relative;
    left: 0; /* Reset left position */
    right: 0; /* Reset right position */
    top: 0; /* Reset top position */
    margin-left: auto; /* Remove left margin auto */
    margin-right: auto; /* Remove right margin auto */
    font-size: 0.5em;

  }


  .footer-bottom {
    margin-bottom: 2em;
    font-size: 0.8em;

  }

  .footer-bottom p {
    margin-left: 5rem;  

  }
}




/* Large Desktops */
@media (min-width: 1440px) and (max-width: 1710px) {
  .header-hero-bg {
    padding: 0 5em;
    height: 90vh;
  }



  .logo {
    width: 10.31em;
    height: 5em;
    top: -5em; /* Adjust the value to move the logo upwards */
    margin-bottom: 2em;
  }

  .header-buttons-container {
    position: absolute;
    top: 3.5rem; /* Adjust the value as needed */
    right: 2rem; /* Adjust the value as needed */
  }


  .gift-cards-img {
    width: 28em; /* Larger size for larger screens */
    height: 32em;
    right: 10%;
    top: 22%;
  }

  .gift-heading {
    font-size: 4em;
    top: -8em; /* Adjust the value to move the logo upwards */
    left: 7.3em;
  }

  .gift-description {
    font-size: 1.5em;
    width: 25em;
  }

  .custom-button {
    padding: 1.5em 3em;
  }

  .button-text {
    font-size: 1.3em;
  }


  .gift-heading {
    margin-top: -0.5em;
  }


  .how-it-works-steps {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four items per row */
    gap: 2rem; /* Add gap between the grid items */
    justify-content: center; /* Center the grid items */
    width: 70%; /* Adjust the width of the grid container */
    margin: 0 auto; /* Center the grid container */
  }

  .how-it-works-step {
    max-width: 350px; /* Maximum width for each card */
    min-width: 250px; /* Minimum width for each card */
  }



  .why-choose-us-grid {
    display: grid;
    grid-template-rows: auto auto auto; /* Three rows */
    gap: 1em; /* Adjust gap as needed */
    width: 80vw; /* Slightly smaller grid */
  }

  .popular-categories-cards {
    justify-content: space-around; /* Ensure cards are spaced evenly */
  }
  
  .wide-range, .easy-payments, .digital-delivery {
    width: 100%; /* Make grid items full width on small screens */
    height: auto; /* Adjust height as needed */
    justify-content: space-around; /* Ensure cards are spaced evenly */

  }


  .customer-support .card-image {
    width: 105%;
    height: 100%;
    object-fit: cover; /* Ensure the image fits within the container */
  }


  .customer-reviews-section {
    padding: 5em 0; /* Adjust padding as needed */
  }

  .customer-reviews-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em; /* Adjust margin for better spacing */
  }

  .customer-reviews-title {
    font-size: 2em; /* Adjust font size for better readability */
  }

  .customer-review {
    width: 80vw; /* Adjust width to fit better on larger screens */
    height: 50vh; /* Set a fixed height */
    background-size: contain; /* Ensure the background image scales properly */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent background image from repeating */
    border-radius: 31.6px;
    padding: 1.5em;
    position: relative;
    text-align: left;
    margin: 0 auto; /* Center the review */
  }

  .customer-review-text {
    color: white;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 2em; /* Adjust font size for better readability */
    position: relative;
    top: 20%; /* Center vertically */
    width: 80%;
    transform: translateY(-50%); /* Adjust to center text vertically */

  }
    .footer-top {
      flex-direction: column;
      align-items: center;
      margin-top: 2em;
    
  }
  .footer-bottom {
    margin-bottom: 4em;
    font-size: 0.8em;

  }
}





/* Very Large Desktops */
@media (min-width: 1711px) {
  .header-hero-bg {
    padding: 0 7em;
    height: 95vh;
  }


  .logo {
    width: 8.0em;
    height: 3.5em;
    top: -13em; /* Adjust the value to move the logo upwards */
    margin-top: 0; /* Remove the default margin-top */

  }

  .header-buttons-container {
    left: 118em; /* Move left by 20 pixels */
    top: 3em;
  }


  .header-button {

    font-size: 1.2rem; /* Font size */
  }

  
  .gift-cards-img {
    width: 40em; /* Even larger size for very large screens */
    height: 45em;
    right: 15%;
    top: 12%;

  }
  .gift-heading {
    font-size: 4em;
    top: -10em; /* Adjust the value to move the logo upwards */
    left: 7.3em;
  }



  .how-it-works-steps {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four items per row */
    gap: 2rem; /* Add gap between the grid items */
    justify-content: center; /* Center the grid items */
    width: 95%; /* Adjust the width of the grid container */
    margin: 0 auto; /* Center the grid container */
  }

  .how-it-works-step {
    max-width: 350px; /* Maximum width for each card */
    min-width: 300px; /* Minimum width for each card */
  }

  .popular-categories-cards {
    justify-content: space-around; /* Ensure cards are spaced evenly */
    width: 70%; /* Adjust the width of the grid container */
    margin-left: 20em;

  }

  .gift-description {
    font-size: 1.8em;
    width: 30em;
  }

  .custom-button {
    padding: 2em 4em;
  }

  .button-text {
    font-size: 1.4em;
  }


  .gift-heading {
    margin-top: -0.5em;
  }


    .why-choose-us-grid {
      grid-template-columns: repeat(3, 1fr); /* Three columns for larger screens */
      grid-template-rows: repeat(2, auto); /* Adjust rows as needed */
      gap: 2em; /* Adjust gap as needed */
      width: 60vw; /* Adjust width as needed */
      height: 75vh; /* Adjust height as needed */
            }
    
    .wide-range, .easy-payments, .digital-delivery, .customer-support {
      width: 100%; /* Make grid items full width on larger screens */
      height: auto; /* Adjust height as needed */
    }

    .faq-card {
      width: 80vw; /* Take 90% of the viewport width */
    
    }
    
    
    .how-it-works-steps {
      justify-content: space-between; /* Ensure cards are spaced evenly */
    }
  
    .how-it-works-step {
      width: calc(23% - 1.5em); /* Four items per row, slightly adjusted */
      max-width: 23%; /* Ensure they don't exceed the row */
    }


    .footer-top {
      flex-direction: column;
      align-items: center;
      margin-top: 2em;
    
  }
  .footer-bottom {
    margin-bottom: 4em;
    font-size: 0.8em;

  }
}
