/* Background overlay for the modal */
.delete-account-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 999; /* Ensure it is below the modal */
  }
  
  .delete-account-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px; /* Adjust width */
    height: 250px; /* Adjust height */
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure it overlays other elements */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .delete-account-modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .delete-account-close-button {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 20px;
    cursor: pointer;
  }
  


  .delete-account-title {
    color: #000;
    text-align: center;
    font-family: "Space Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px; /* Add margin bottom */
  }
  
  .delete-account-button {
    color: #969696;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.5px; /* Adjust line-height */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px; /* Add margin to space buttons */
  }
  
  .delete-account-button-cancel {
    background: #FFF;
    color: #969696;
border: 1px solid #969696;
  }
  
  .delete-account-button-delete {
    background: #F01616;
    color: #fff;
  }
  
  .delete-account-button-delete:hover {
    background: #e04e4e;
  }
  
  .delete-account-modal-content > div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px; /* Add margin top */
  }
  