/* Styling for ForgotPassword.js page */
.password-form-group-custom,
.forgot-password-button-custom {
  max-width: 350px; /* Set your desired max-width here */
  width: 100%; /* Ensure they take the full width available up to max-width */
}

.forgot-password-container-custom {
  display: flex;
  height: 60vh;
  width: 50vw;
  margin: auto;
  border-radius: 8px; /* optional: adds rounded corners */
  overflow: hidden; /* optional: ensures children do not overflow */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.forgot-password-left-custom {
  width: 40%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: #ffffff;
}

.forgot-password-right-custom {
  width: 60%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.forgot-password-logo {
  width: 15em; /* Adjust the logo size as needed */
  height: auto; /* Maintain aspect ratio */
}

.forgot-password-title-custom {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 32px;
  font-weight: bold;
  line-height: normal;
  text-align: left;
  color: rgb(39, 39, 39);
  margin-bottom: 1rem; /* Margin below the title */
}

.forgot-password-form-group {
  width: 100%;
  margin-bottom: 1rem; /* Margin below the form group */
}

.forgot-password-input {
  width: 100%; /* Ensure it takes the full width of the container */
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  font-family: Inter;
  margin-bottom: 1rem; /* Margin below the input */
  margin-top: 0.5rem;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.forgot-password-button {
  width: 100%; /* Ensure it takes the full width of the container */
  padding: 15px;
  background-color: #830eca; /* Adjust color to match the second image */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 1rem; /* Margin below the button */
  font-family: Inter;
  transition: background-color 0.3s ease;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.forgot-password-button:hover {
  background-color: #6a0ca9; /* Darker shade for hover effect */
}

.back-to-login-link {
  width: 100%; /* Ensure it takes the full width of the container */
  text-align: left; /* Left align the link */
  font-size: 14px;
  color: #830eca;
  text-decoration: none;
}

.back-to-login-link a {
  color: #830eca;
  text-decoration: none;
}

.back-to-login-link a:hover {
  text-decoration: underline;
}
