.AddMoneyModal-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.AddMoneyModal-modal-content {
    background: white;
    padding: 20px;
    border-radius: 12px;
    width: 20%;
    max-width: 500px;
    font-family: 'Graphik Regular', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AddMoneyModal-modal-header {
    display: flex;
    justify-content: space-between; /* Change to space-between */
    align-items: center;
    padding-bottom: 10px;
    width: 100%;
}

.AddMoneyModal-modal-header h3 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Space Grotesk', sans-serif;
    color: rgb(131, 14, 202);
    margin: 0;
    flex: 1; /* Take up remaining space */
    text-align: center; /* Center align the header */
}

.AddMoneyModal-close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.AddMoneyModal-back-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.AddMoneyModal-modal-body {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.AddMoneyModal-add-money-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
}

.AddMoneyModal-add-money-option {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 0.8em;
}




.AddMoneyModal-generate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .AddMoneyModal-generate-message {
    font-size: 12px;
    margin-bottom: 20px;
    font-family: 'inter';
  }
  
  .AddMoneyModal-generate-button {
    background-color: #6a1b9a;
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s;
    font-family: 'inter';

  }
  
  .AddMoneyModal-generate-button:hover {
    background-color: #5a148a;
  }
  

.AddMoneyModal-add-money-option:hover {
    background: #f0f0f0;
}

.AddMoneyModal-add-money-option img {
    width: 24px;
    height: 24px;
}

.AddMoneyModal-account-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 5em;
}

.AddMoneyModal-account-details p {
    margin: 2px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Graphik Regular';
    font-size: 14px;
}

.AddMoneyModal-account-details .label {
    margin-right: 20px; /* Adjust this value to create space between the label and the value */
}

.AddMoneyModal-account-details span {
    text-align: left; /* Aligns the span text to the left */
    display: inline-block; /* Allows the span to take only the required width */
    min-width: 150px; /* Set a minimum width to align all spans */
    white-space: nowrap; /* Prevent text from wrapping */
    font-family: 'Graphik Regular';
    font-size: 14px;
}

.AddMoneyModal-copy-button {
    display: block;
    margin: 10px auto; /* Center horizontally with auto, adjust top/bottom margins as needed */
    padding: 10px;
    background-color: rgb(131, 14, 202);
    color: white;
    border: none;
    font-family: 'Graphik Medium';
    font-size: 12px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    width: 60%; /* Adjust the width as needed */
    max-width: 200px; /* Optional: Set a maximum width */
    margin-left: 30px; /* Adjust this value to move the button to the left */
    margin-top: 30px; /* Adjust this value to move the button up or down */
}

.AddMoneyModal-copy-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px; /* Adjust this value to move the button up or down */
    margin-bottom: 10px; /* Adjust this value to control the space below the button */
}

.AddMoneyModal-copy-button:hover {
    background-color: rgb(105, 0, 190); /* Darker shade on hover */
}

.AddMoneyModal-copy-button:active {
    background-color: rgb(34, 3, 60); /* Even darker shade on click */
}

.AddMoneyModal-input-wrapper {
    position: relative;
    width: 100%;
}

.AddMoneyModal-modal-body .fund-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.AddMoneyModal-modal-body .fund-card label {
  width: 80%; /* Take 80% of the modal width */
  text-align: left;
  margin-bottom: 5px;
  font-family: 'Graphik Medium';
  font-size: 12px;
}

.AddMoneyModal-modal-body .fund-card input {
    width: 100%; /* Adjust the width as needed */
    max-width: 500px; /* Set a max width to avoid extra width from placeholder */
    font-family: 'Graphik Regular';
    font-size: 14px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center; /* Center the text in the input */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.AddMoneyModal-modal-body .AddMoneyModal-fund-card .AddMoneyModal-continue-button {
    width: 100%; /* Make the button the same width as the input */
    max-width: 400px; /* Set the same max width as the input */
    padding: 12px 20px;
    background-color: rgb(131, 14, 202);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    margin-top: 20px; /* Add some space between the input and the button */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
