.unique-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .unique-modal-container {
    background: white;
    border-radius: 8px;
    width: 450px;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
  }
  
  .unique-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Space Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #830ECA;
    margin-bottom: 20px;
    position: relative;
    padding: 20px 0; /* Add space at the top and bottom */
}

  .unique-modal-header h2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
   

  }

  .unique-modal-close-button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

  .unique-modal-body {
    margin-top: 20px;
  }
  


  .unique-modal-input-wrapper {
    position: relative;
    margin-bottom: 15px;

  }



  .unique-modal-input-group label {
    display: block;
    margin-bottom: 5px;
    color: #2B2B2B;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
  }
  

  
  .unique-modal-input-wrapper input {
        width: 100%;
    padding: 10px;
    border: 1.094px solid #D8D8D8;
    border-radius: 4px;
    font-family: Inter;
    font-size: 14px;  
    padding-right: 40px; /* Add space for visibility toggle button */
    box-sizing: border-box;
}


.unique-modal-visibility-toggle {
    background: none;
    border: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1rem;
  }
  .unique-modal-success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }
  
  .unique-modal-success-image {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  .unique-modal-success-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .unique-modal-success-content p {
    font-size: 16px;
    color: #666;
  }
  

  .unique-modal-change-password-button {
    width: 100%;
    padding: 10px;
    background: #830ECA;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #FFF;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
line-height: 100%; /* 17.506px */
  }
  
  .unique-modal-change-password-button:hover {
    background: #6a0dad;
  }
  